import React, { useEffect, useState, ChangeEvent } from 'react';
import { Col, Row, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { post } from 'services/ApiService';
import InputError from '../../components/InputError';
import Required from '../../components/Required';
import AlertModal from '../../components/AlertModal';
import ConfirmModal from '../../components/ConfirmModal';
import { useAlertPlus, useConfirm } from 'hooks';
import { useHistory } from 'react-router-dom';
import DatePicker, { DatePickerChangeEvent } from 'components/DatePicker';
import { useUser } from 'context/UserContext';
import { getPractices } from 'features/practices/practicesAPI';
import { InsurerPractices } from 'types/Request';
import moment from 'moment';

interface Authorization {
  insurer_id: number;
  recipient_id: string;
  practice_id: number;
  practice_text: string;
  additional_id: number;
  additional_text: string;
  sessions: number;
  prescriber_id: string;
  prescription_date?: Date;
}

export default function Avalian() {
  // Autorizacion
  const [formData, setFormData] = useState<Authorization>({
    insurer_id: 36,
    recipient_id: '',
    practice_id: 7,
    practice_text: 'Modulo Consultorio',
    additional_id: -1,
    additional_text: '',
    sessions: 1,
    prescriber_id: '',
  });

  const history = useHistory();

  const { getMatricula } = useUser();
  const matriculateId = getMatricula();
  if (!matriculateId) {
    return;
  }

  const [prestaciones, setPrestaciones] = useState<InsurerPractices>({
    modules: [],
    additionals: [],
  });

  const [formValidation, setFormValidation] = useState<Partial<Record<keyof typeof formData, boolean>>>({});

  const [alertShow, alertMessage, alertCallback, setAlert] = useAlertPlus('');
  const [isLoading, setLoading] = useState(true);
  const [confirm, confirmTitle, confirmAction, setConfirm] = useConfirm(false);

  const handleFormValidation = () => {
    let newFormValidation = {};

    let toValidate = [...document.getElementsByClassName('form-control')] as HTMLInputElement[];
    toValidate = toValidate.filter(e => !e.disabled && e.required);

    let missing = false;
    // Validar campos required
    toValidate.forEach(field => {
      if (field.required && !field.disabled && !field.value?.trim()) {
        field.classList.add('is-invalid');

        missing = true;
      } else {
        field.classList.remove('is-invalid');
      }
    });

    // Validar file inputs required
    toValidate = [...document.getElementsByClassName('custom-file-input')] as HTMLInputElement[];
    toValidate.forEach(field => {
      if (field.required && !field.disabled) {
        field.classList.add('is-invalid');

        missing = true;
      }
    });

    // Validar cantidad de sesiones
    if (formData.sessions < 0 || formData.sessions > 5) {
      newFormValidation = {
        ...newFormValidation,
        sessions: false,
      };

      missing = true;
    }

    if (missing) {
      setFormValidation(newFormValidation);
      setAlert('Todos los campos marcados con * son obligatorios. ' +
        'Por favor complete los faltantes antes de enviar la solicitud.');
      return false;
    }

    return true;
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement> | DatePickerChangeEvent) => {
    const { target } = event;

    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const extra: Partial<typeof formData> = {};

    // Si cambiaron los id de práctica, recuperamos el nuevo nombre
    switch (name) {
      case 'practice_id':
        if (typeof value === 'string') {
          extra['practice_text'] = prestaciones.modules.find(p => p.id === parseInt(value))!.name;
        } else {
          extra['practice_text'] = undefined;
        }
        break;
      case 'additional_id':
        if (typeof value === 'string') {
          extra['additional_text'] = prestaciones.additionals.find(p => p.id === parseInt(value))!.name;
        } else {
          extra['additional_text'] = undefined;
        }
        break;
    }

    // Guardamos el nuevo valor
    setFormData({
      ...formData,
      ...extra,
      [name]: value,
    });
  };

  const handleAuthorization = async () => {
    setLoading(true);

    console.info('Avalian', 'Iniciando proceso de autorización');

    if (!handleFormValidation()) {
      setLoading(false);
      return;
    }

    if (!Object.values(formValidation).every(v => v)) {
      console.info('Avalian', 'Error de validación');
      setAlert('Se encontraron errores en los datos ingresados. Por favor, solucione los mismos antes de enviar' +
        'el pedido de autorización.');
      setLoading(false);
      return;
    }

    const response = await post('insurers/authorize', {
      ...formData,
      prescription_date: formData.prescription_date!.toISOString().split('T')[0],
    });

    // Errores
    if (response.status === 'error' && response.code !== 'AUTHORIZATION_ERROR') {
      console.error('Avalian', 'Se produjo un error al intentar realizar la autorización');
      console.info('Avalian', response);
      if (response.message) {
        setAlert(response.message);
      } else {
        setAlert('Hubo un problema al intentar autorizar la práctica. Por favor, intente nuevamente más tarde');
      }

      setLoading(false);
      return;
    }

    // Rechazos
    if (response.code === 'AUTHORIZATION_ERROR') {
      if (response.message) {
        setAlert(response.message);
      } else {
        console.error('Avalian', 'Práctica no autorizada por motivo desconocido');
        console.info('Avalian', response);
        setAlert('No se autorizó la práctica');
      }

      setLoading(false);
      return;
    }

    console.info('Avalian', 'Práctica autorizada');

    const id = response.payload?.billing_request?.id;

    setLoading(false);

    if (!id) {
      console.error('Avalian', 'Error al recuperar la solicitud asociada con la autorización');
      console.info('Avalian', response);

      setAlert(`Se produjo un error al intentar recuperar la información sobre la autorización. Por favor, ` +
          `intente nuevamente`, () => {
        history.replace(`/solicitudes`);
      });
    }

    setAlert(`La práctica fue autorizada por la Obra Social con el ` +
      ` número ${ response.payload.authorization_id }`, () => {
      history.replace(`/solicitudes`);
    });
  };

  useEffect(() => {
    getPractices({
      insurerId: formData.insurer_id,
      matriculateId: parseInt(matriculateId),
      date: moment().format('YYYY-MM-DD'),
    })
      .then(data => {
        if (!data) {
          throw new Error('Hay problemas de conexión con el sistema de Facturación. ' +
            'Temporalmente no se podrá enviar la solicitud.');
        }

        setPrestaciones({
          modules: data.modules ?? [],
          additionals: data.additionals ?? [],
        });
      })
      .catch(err => {
        setAlert(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Form onKeyPress={ e => e.key === 'Enter' && handleAuthorization() }>
        <FormGroup>
          <Row form>
            <Col sm={6}>
              <Label for="numero_afiliado">Número de Beneficiario <Required /></Label>
              <Input
                id="recipient_id"
                name="recipient_id"
                type="text"
                size={10}
                maxLength={10}
                invalid={ formValidation.recipient_id === false }
                onChange={ handleInputChange }
                disabled={ isLoading }
                value={ formData.recipient_id }
                required
              />
              {
                formValidation.recipient_id === false
                  ? <InputError message="El número de afiliado ingresado no es válido" />
                  : null
              }
              <small className="text-muted">
                Se deben ingresar todos los numeros que figuran en la credencial sin espacios o barras
              </small>
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row form>
            <Col sm={6}>
              <Label for="practice_id">Código de Práctica <Required /></Label>
              <Input
                id="practice_id"
                name="practice_id"
                type="select"
                invalid={ formValidation.practice_id === false }
                onChange={ handleInputChange }
                disabled={ isLoading }
                value={ formData.practice_id }
              >
                {
                  prestaciones.modules && Array.isArray(prestaciones.modules)
                    ? prestaciones.modules.map(item => <option key={ item.id } value={ item.id }>{ item.name }</option>)
                    : null
                }
              </Input>
            </Col>
            <Col sm={6}>
              <Label for="additional_id">Práctica adicional <Required /></Label>
              <Input
                id="additional_id"
                name="additional_id"
                type="select"
                invalid={ formValidation.additional_id === false }
                onChange={ handleInputChange }
                disabled={ isLoading }
                value={ formData.additional_id }
              >
                <option value="-1">Ninguna</option>
                {
                  prestaciones.additionals && Array.isArray(prestaciones.additionals)
                    ? prestaciones.additionals.map(item => (
                      <option key={ item.id } value={ item.id }>{ item.name }</option>
                    ))
                    : null
                }
              </Input>
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row form>
            <Col sm={6}>
              <Label for="sessions">Cantidad de Sesiones <Required /></Label>
              <Input
                id="sessions"
                name="sessions"
                type="number"
                min="1"
                invalid={ formValidation.sessions === false }
                onChange={ handleInputChange }
                disabled={ isLoading }
                value={ formData.sessions }
                required
              />
              {
                formValidation.sessions === false
                  ? <InputError message="La cantidad de sesiones tiene que ser entre 1 y 5 inclusive" />
                  : null
              }
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row form>
            <Col sm={6}>
              <Label for="prescriber_id">Matricula del Medico Prescriptor <Required /></Label>
              <Input
                id="prescriber_id"
                name="prescriber_id"
                type="number"
                min="1"
                onChange={ handleInputChange }
                disabled={ isLoading }
                value={ formData.prescriber_id }
                required
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row form>
            <Col sm={6}>
              <Label for="prescription_date">Fecha de Prescripción <Required /></Label>
              <DatePicker
                id="prescription_date"
                name="prescription_date"
                selected={ formData.prescription_date ?? '' }
                invalid={ formValidation.prescription_date === false }
                onChange={ (date: Date | null) => {
                  return handleInputChange({
                    target: {
                      name: 'prescription_date',
                      type: 'datepicker',
                      value: date,
                    },
                  });
                }}
                maxDate={ new Date() }
                className="date-picker form-control"
                dateFormat="dd/MM/yyyy"
                locale="es"
                disabled={ isLoading }
                autoComplete="off"
                required
              />
              {
                formValidation.prescription_date === false
                  ? <InputError message="La fecha de prescripción ingresada no es válida" />
                  : null
              }
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <div className="text-center">
            <Button type="button" onClick={ handleAuthorization } disabled={ isLoading }>Autorizar</Button>
          </div>
        </FormGroup>
      </Form>
      <AlertModal isOpen={ alertShow } message={ alertMessage } onClose={ alertCallback } />
      <ConfirmModal
        isOpen={ confirm }
        title={ confirmTitle }
        action={ confirmAction }
        onClose={ () => setConfirm(false) }
        onConfirm={ () => handleAuthorization() }
      />
    </>
  );
}
