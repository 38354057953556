import React, { useState } from 'react'
import { Card, CardHeader, Col, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap'

function RequestSearch(props) {
  const { onSearch, currentQuery } = props

  const [query, setQuery] = useState(currentQuery)
  const [isFocused, setIsFocused] = useState(false);

  const searchButtonStyle = {
    border: `1px solid ${isFocused ? '#9a9a9a' : '#dddddd'}`,
    borderBottomRightRadius: '.25rem',
    borderTopRightRadius: '.25rem',
    width: '40px'
  }

  const resetButtonStyle = {
    borderLeft: `1px solid ${isFocused ? '#9a9a9a' : '#dddddd'}`,
    borderTop: `1px solid ${isFocused ? '#9a9a9a' : '#dddddd'}`,
    borderBottom: `1px solid ${isFocused ? '#9a9a9a' : '#dddddd'}`,
    borderRight: 'none',
    width: '40px'
  }

  const resetSearch = () => {
    setQuery('')
    onSearch('')
  }

  return (
    <Card className="shadow mb-3">
      <CardHeader>
        <Row>
          <Col>
            <InputGroup>
              <Input
              type="text"
              placeholder="Buscar..."
              onChange={ event => setQuery(event.target.value) }
              onKeyUp={ event => event.key === 'Enter' && onSearch(query) }
              value={ query }
              onBlur={() => setIsFocused(false)}
              onFocus={() => setIsFocused(true)} />
              <InputGroupAddon addonType="append">
                {
                  currentQuery !== ''
                  ? <button className="btn-primary" style={ resetButtonStyle } onClick={ () => resetSearch() }>
                      <i className="fas fa-times"></i>
                    </button>
                  : null
                }
                <button className="btn-primary" style={ searchButtonStyle } onClick={ () => onSearch(query) }>
                  <i className="fas fa-search"></i>
                </button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>
      </CardHeader>
    </Card>
  )
}

export default RequestSearch
