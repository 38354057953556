import {
  Request,
  RawRequest,
  RequestFiles,
  SessionRaw,
  InsurerPractices,
  AttachmentType,
  AttachmentRaw } from 'types/Request';
import { getOne, update, deleteOne } from 'services/ApiService';
import config from 'config';
import moment from 'moment';

export async function getRequest(id: number): Promise<{ request: Request, files: RequestFiles } | undefined> {
  try {
    const res: RawRequest = await getOne('requests', id);
    if (!res.id || !res.ooss_id) {
      return;
    }

    const request = parseRequest(res);
    if (!request) {
      return;
    }

    const files = parseFiles(res.attachs);

    return {
      request,
      files,
    };
  } catch (err) {
    console.error(err);
    return;
  }
};

export async function updateRequest(request: Request, files: RequestFiles, practices?: InsurerPractices) {
  const res: RawRequest = await update('requests', request.id, {
    estado_id: request.status_id,
    ooss_id: request.insurer_id,
    numero_afiliado: request.recipient_id,
    diagnostico: request.diagnosis,

    numero_aprobacion: request.authorization_id,
    authorization_date: request.authorization_date?.toISOString().split('T')[0],

    idTipoPractica: request.practice_id,
    tipo_practica: practices?.modules.find(p => p.id === parseInt(`${ request.practice_id }`))?.name,
    idPracticaAdicional: request.additional_id,
    practica_adicional: request.additional_id
      ? practices?.additionals.find(p => p.id === parseInt(`${ request.additional_id }`))?.name
      : null,

    fecha_prescripcion_medica: request.prescription_date?.toISOString().split('T')[0],
    cantidad_sesiones: request.max_sessions,

    aprobacion_previa: request.previously_authorized,
    prestacion_discapacidad: request.impairment,
    es_refacturacion: request.rebilling,
    quirurgical: request.quirurgical,
    exceptional: request.exceptional,
    exceptional_text: request.exceptional ? request.exceptional_text : null, // Sólo si es excepción

    consultorio_id: request.consultorio_id ? request.consultorio_id : null,

    updatedAt: new Date().toISOString(),
    affiliate: {
      ooss_id: request.insurer_id,
      numero_afiliado: request.recipient_id,
      nombre_apellido: request.recipient_name,
      edad: request.recipient_age,
    },

    attachs: Object.fromEntries(
      Object.entries(files).filter(file => !!file[1] && file[1].hasOwnProperty('url')),
    ),

    sessionDates: request.sessions.map(session => ({
      date: session.date?.toISOString().split('T')[0],
      approvalNumber: session.authorization_id,
      online_authorization: session.online,
    })),
  }, prepareFiles(files));

  if (!res.id || !res.ooss_id) {
    return;
  }

  const responseRequest = parseRequest(res);
  if (!responseRequest) {
    return;
  }

  const responseFiles: RequestFiles = parseFiles(res.attachs);

  return {
    request: responseRequest,
    files: responseFiles,
  };
}

export async function createObservation(request: Request, observation: string) {
  const res: RawRequest = await update('requests', request.id, {
    observation: observation,
  });

  return {
    request: res,
    status: res.observations[res.observations.length - 1].text === observation ? 'ok' : 'error',
  };
}

export async function deleteRequest(id: number): Promise<boolean> {
  const res = await deleteOne('requests', id);

  if (!res || !res.status || res.status !== 200) {
    return false;
  }

  return true;
};

const parseRequest = (res: RawRequest): Request | undefined => {
  if (!res.id || !res.ooss_id) {
    return;
  }

  const data: Request = {
    id: res.id,
    status_id: res.estado_id,
    insurer_id: res.ooss_id,
    diagnosis: '',
    exceptional: false,
    impairment: false,
    quirurgical: false,
    max_sessions: 5,
    observations: [],
    previously_authorized: false,
    extended_quota: false,
    consultorio_id: null,
    rebilling: false,
    recipient_age: 0,
    recipient_id: '',
    recipient_name: '',
    recipient_doc: '',
    recipient_plan: '',
    recipient_validated: false,
    authorization_validated: false,
    sessions: [],
    created_at: new Date(res.createdAt),
  };

  // Parseamos las fechas en objetos Date para el DatePicker
  if (res.fecha_prescripcion_medica) {
    data.prescription_date = moment(res.fecha_prescripcion_medica, 'YYYY-MM-DD').toDate();
    data.prescription_date_parsed = moment(res.fecha_prescripcion_medica, 'YYYY-MM-DD').format('DD/MM/YYYY');
  }

  if (res.authorization_date) {
    const authorizationDate = res.authorization_date.indexOf('T') > -1
      ? res.authorization_date.split('T')[0]
      : res.authorization_date;

    data.authorization_date = moment(authorizationDate, 'YYYY-MM-DD').toDate();
    data.authorization_date_parsed = moment(data.authorization_date, 'YYYY-MM-DD').format('DD/MM/YYYY');
  } else if (res.fecha_aprobacion) {
    const fechaAprobacion = res.fecha_aprobacion.indexOf('T') > -1
      ? res.fecha_aprobacion.split('T')[0]
      : res.fecha_aprobacion;

    data.authorization_date = moment(fechaAprobacion, 'YYYY-MM-DD').toDate();
    data.authorization_date_parsed = moment(data.authorization_date, 'YYYY-MM-DD').format('DD/MM/YYYY');
  }

  if (!res.sessionDates || !Array.isArray(res.sessionDates)) {
    res.sessionDates = [];
  }

  res.sessionDates.forEach((session: SessionRaw) => {
    data.sessions.push({
      date: moment(session.date, 'YYYY-MM-DD').toDate(),
      date_parsed: moment(session.date).format('DD/MM/YYYY'),
      authorization_id: session.approvalNumber ?? '',
      coinsurance: session.coinsurance ?? '',
      preAuth: session.preAuth ?? undefined,
      online: session.online_authorization ?? false,
    });
  });

  data.previously_authorized = !!res.aprobacion_previa;
  data.authorization_validated = !!res.aprobacion_validada;
  data.extended_quota = !!res.extended_quota;

  data.recipient_id = res.numero_afiliado ?? '';
  if (res.affiliate) {
    data.recipient_age = res.affiliate.edad ?? 0;
    data.recipient_name = res.affiliate.nombre_apellido ?? '';
    data.recipient_plan = res.affiliate.plan ?? '';
    data.recipient_doc = res.affiliate.documento ?? '';
  }

  data.recipient_validated = res.recipient_validated ?? false;

  data.diagnosis = res.diagnostico ?? '';

  data.impairment = res.prestacion_discapacidad ?? false;

  data.practice_id = res.idTipoPractica ? res.idTipoPractica : undefined;
  data.practice_text = res.tipo_practica ? res.tipo_practica : undefined;

  data.additional_id = res.idPracticaAdicional ? res.idPracticaAdicional : undefined;
  data.additional_text = res.practica_adicional ? res.practica_adicional : undefined;

  data.authorization_id = res.numero_aprobacion;
  data.authorization_transaction_id = res.authorization_transaction_id ?? undefined;

  data.consultorio_id = res.consultorio_id ? res.consultorio_id : null;

  if (res.ooss) {
    data.insurer_shortname = res.ooss.codigo;
    data.insurer_fullname = res.ooss.nombre;
  }

  data.max_sessions = res.cantidad_sesiones ? res.cantidad_sesiones : 5;

  data.observations = res.observations;

  data.recipient_token = res.recipient_token ? res.recipient_token : undefined;

  data.exceptional = res.exceptional ?? false;
  data.exceptional_text = res.exceptional_text ? res.exceptional_text : undefined;

  data.rebilling = res.es_refacturacion ?? false;

  data.quirurgical = !!res.quirurgical;

  return data;
};

const parseFiles = (attachs: AttachmentRaw[]): RequestFiles => {
  if (!attachs && !Array.isArray(attachs)) {
    return {};
  }

  const files: RequestFiles = {};
  attachs.map(file => {
    files[file.tipo] = {
      type: file.tipo_archivo === 'pdf' ? 'application/pdf' : 'image/jpeg', // En realidad aparte de application/pdf, el otro tipo no importa
      url: config.baseUrl + '/' + file.url,
    };
  });

  return files;
};

const prepareFiles = (files: RequestFiles, exclude?: string) => {
  const filesToSend: RequestFiles = {};

  for ( const [key, data] of Object.entries( files ) ) {
    if (exclude && exclude === key) {
      continue;
    }

    if (data instanceof File) {
      filesToSend[key as AttachmentType] = data;
    }
  }

  return filesToSend;
};
