/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { deleteCookie } from 'helpers'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Container,
} from "reactstrap"

import { UserContext } from 'context/UserContext'

import routes from "routes"

function Header () {
  const history = useHistory()
  const location = useLocation()
  const [isOpen, setIsOpen] = useState(false)
  const [color, setColor] = useState('transparent')
  const sidebarToggle = useRef()
  const user = useContext(UserContext)

  useEffect(() => {
    window.addEventListener("resize", updateColor)
  }, [])

  useEffect(() => {
    if (
      window.innerWidth < 993 &&
      history.location.pathname !== location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open")
      sidebarToggle.current.classList.toggle("toggled")
    }
  })

  const toggle = () => {
    if (isOpen) {
      setColor('transparent')
    } else {
      setColor('dark')
    }
    setIsOpen(!isOpen)
  }

  const getBrand = () => {
    let brandName = 'Sistema de Autogestión'
    routes.map((prop, key) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        brandName = prop.displayName
      }
      return null
    })
    return brandName
  }

  const openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    sidebarToggle.current.classList.toggle("toggled");
  }

  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && isOpen) {
      setColor('dark')
    } else {
      setColor('transparent')
    }
  }

  const logout = () => {
    // Borramos la cookie
    deleteCookie('accessToken')
    // Quitamos los parámetros que haya en la URL
    history.replace(history.location.pathname)
    // Forzamos la recarga para que se muestra la pantalla de sesión cerrada
    history.go(0)
  }

  return (
    // add or remove classes depending if we are on full-screen-maps page or not
    <Navbar
      color={
        location.pathname.indexOf("full-screen-maps") !== -1
          ? "dark"
          : color
      }
      expand="lg"
      className={
        location.pathname.indexOf("full-screen-maps") !== -1
          ? "navbar-absolute fixed-top"
          : "navbar-absolute fixed-top " +
            (color === "transparent" ? "navbar-transparent " : "")
      }
    >
      <Container fluid>
        <div className="navbar-wrapper">

          <div className="navbar-toggle">
            <button type="button" ref={sidebarToggle} className="navbar-toggler" onClick={() => openSidebar()}>
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>

          <span className='navbar-brand'>{ getBrand() }</span>

        </div>

          <NavbarToggler onClick={ toggle }>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>

        <Collapse isOpen={isOpen} navbar className="justify-content-end">
          <Nav navbar>
            <NavItem className="navbar-user">
              <i className="nc-icon nc-single-02 d-lg-none d-md-inline-block" />
              <label className="d-none d-lg-inline">Usuario: </label>
              <p> {user.getMatricula()} - {user.getNombreApellido()}</p>
            </NavItem>
            <NavItem className="ml-2 btn btn-link btn-sm" onClick={ () => logout() }>
              <i className="fas fa-sign-out-alt" title="Cerrar Sesión"></i>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  )
}

export default Header
