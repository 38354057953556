import React, { useEffect, useState } from 'react'
import CollapsibleCard from '../../components/CollapsibleCard'
import { Col, Row, FormGroup, Input } from 'reactstrap'
import { getAll } from 'services/ApiService'
import { useAlertPlus } from 'hooks'
import AlertModal from 'components/AlertModal'

function RequestFilters(props) {
  const { changeFilter } = props

  const [ insurers, setInsurers ] = useState([])

  const [ showAlert, message, alertCallback, alert ] = useAlertPlus('')

  useEffect(() => {
    getAll('ooss', {
      sort: {
        field: 'codigo',
        dir: 1
      }
    })
    .then(res => {
      setInsurers(res.rows)
    })
    .catch(err => {
      alert('Ha ocurrido un error al obtener el listado de Obras Sociales, temporalmente no se podrá filtrar por obra social.')
      console.log(err)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CollapsibleCard title="Filtros">
      <Row>
        <Col md="4">
          <FormGroup>
            <label>Estado</label>
            <Input type="select" onChange={ (event) => changeFilter('status', event.target.value) } defaultValue="any">
              <option value="any">Todos</option>
              <option value="pending">Pendiente</option>
              <option value="presented">Presentado</option>
              <option value="observed">Observado</option>
              <option value="approved">Aprobado</option>
            </Input>
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup>
            <label>Obra Social</label>
            <Input type="select" onChange={ (event) => changeFilter('ooss_id', event.target.value) } defaultValue="any">
              <option value="any">Todas</option>
              {
                insurers && Array.isArray(insurers)
                ? insurers.map(insurer => <option key={'insurer_' + insurer.id} value={ insurer.id }>{ insurer.codigo }</option>)
                : null
              }
            </Input>
          </FormGroup>
        </Col>
        <AlertModal isOpen={ showAlert } message={ message } onClose={ alertCallback } />
      </Row>
    </CollapsibleCard>
  )
}

export default RequestFilters
