import React, { ReactNode } from 'react';

interface FakeInputProps {
  value: ReactNode;
}

export default function FakeInput(props: FakeInputProps) {
  const { value } = props;

  return (
    <div
      className="form-control"
      style={{ backgroundColor: '#E3E3E3', color: '#66615B', cursor: 'not-allowed' }}
    >
      { value }
    </div>
  );
}
