import React from 'react'

function InputError(props) {
  const { message } = props

  const customStyle = {
    width: '100%',
    marginTop: '.25rem',
    fontSize: '80%',
    color: '#dc3545'
  }

  return <span style={ customStyle }>{ message }</span>
}

export default InputError