import React from 'react'
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap"

function Collapsible(props) {
  const { title, children } = props

  return (
    <Card className="shadow mb-3">
      <CardHeader>
        <Row>
          <Col xs={12} className="text-left d-flex">
            <h5 className="mb-3">{ title }</h5>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        { children }
      </CardBody>
    </Card>
  )
}

export default Collapsible