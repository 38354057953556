import React, { useRef } from "react"
import { Link, NavLink, useLocation } from "react-router-dom"
import { Nav } from "reactstrap"
import config from 'config'

function Sidebar (props) {
  const sidebar = useRef()
  const location = useLocation()

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : ""
  }

  return (
    <div className="sidebar" data-color={props.bgColor} data-active-color={props.activeColor}>
      <div className="logo">
        <Link to='/solicitudes' className="simple-text">
          <div className="logo-img pr-2 pl-2">
            <img src={require('../../logo-cokiba.png')} alt="COKIBA" />
          </div>
        </Link>
        <div className="small text-right text-light font-weight-bold">v{ config.version }{ config.build ? '(' + config.build + ')' : '' }{ config.env !== 'prod' ? ` - ${ config.env }` : '' }</div>
      </div>

      <div className="sidebar-wrapper" ref={sidebar}>
        <Nav>
          {props.routes.map((prop, key) => {
            if (!prop.hide) {
              return (
                <li className={
                  activeRoute(prop.layout + prop.path) + (prop.pro ? " active-pro" : "")
                } key={key} >
                  <NavLink exact to={prop.layout + prop.path} className="nav-link" activeClassName="active" >
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </NavLink>
                </li>
              )
            } else {
              return (null)
            }
          })}
        </Nav>
      </div>

    </div>
  )
}

export default Sidebar
