import React, { ChangeEvent, useState } from 'react';
import { Col, Label, Input } from 'reactstrap';

interface DNIProps {
  disabled: boolean;

  onChange: (recipient: string[]) => void;
}

export default function DNI(props: DNIProps) {
  const { disabled = false, onChange } = props;

  const [type, setType] = useState(1);
  const [recipient, setRecipient] = useState('');

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRecipient(event.target.value);

    onChange([event.target.value]);
  };

  return (
    <>
      <Col sm={ 6 } md={ 3 }>
        <Label for="document_type">Tipo de Documento</Label>
        <Input
          type="select"
          id="document_type"
          name="document_type"
          onChange={ event => setType(event.target.valueAsNumber) }
          disabled={ disabled }
          value={ type }
        >
          <option value="1">DNI</option>
          <option value="2">Libreta de enrolamiento</option>
          <option value="3">Libreta cívica</option>
          <option value="5">Pasaporte</option>
          <option value="7">Documento extranjero</option>
          <option value="8">Documento recién nacido (en trámite)</option>
        </Input>
      </Col>
      <Col sm={ 6 } md={ 3 }>
        <Label for="recipient_id">N° de Documento</Label>
        <Input
          id="recipient_id"
          name="recipient_id"
          type="number"
          step={ 1}
          min={ 1 }
          max={ 9999999999 }
          pattern="\d+"
          style={{ textAlign: 'right' }}
          value={ recipient }
          onChange={ handleInputChange }
          disabled={ disabled }
        />
      </Col>
    </>
  );
}
