import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { valid, lt } from 'semver';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Col,
  UncontrolledAlert,
  Modal,
  ModalHeader,
  ModalFooter,
  Alert,
} from 'reactstrap';

import Filters from '../../components/Requests/Filters';
import Pagination from 'components/Pagination';

import Table from './Table';
import Search from './Search';

import { getAll, getQueryString } from 'services/ApiService';
import { useAlert } from 'hooks';
import AlertModal from 'components/AlertModal';
import config from 'config';
import { getCookie } from 'helpers';
import { deleteRequest } from 'features/requests/requestsAPI';
import { RawRequest } from '@cokiba/types';
import { useConfig } from 'context/ConfigContext';

interface Options {
  estado_id: number[];
  sort: {
    field: string;
    dir: 1 | -1;
  };
  page: number;
  search: string;
}

interface ModalContent {
  title: string;
  id: number;
}

interface ExpirationInformation {
  colors?: string;
  diffDays?: number;
}

type RequestWithExpiration = RawRequest & ExpirationInformation

function List() {
  const [loading, isLoading] = useState(true);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [total, setTotal] = useState(0);

  const [success, setSuccess] = useState<boolean>();
  const [successText, setSuccessText] = useState<string>();

  const [items, setItems] = useState<RequestWithExpiration[]>([]);

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState<ModalContent>({
    title: '',
    id: -1,
  });

  const [showAlert, message, alert] = useAlert(false, '');
  const c = useConfig();
  // Opciones de paginacion, filtro y orden del listado
  const [currentOptions, setCurrentOptions] = useState<Options>({
    estado_id: [1, 4],
    sort: {
      field: 'createdAt',
      dir: -1,
    },
    page: 1,
    search: '',
  });
  // Carga inicial de datos
  useEffect(() => {
    fetch(`${ config.baseUrl }/system/version`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(res => {
        if (!res || !res.au) {
          return;
        }

        const currentVersion = valid(res.au);
        if (!currentVersion) {
          return;
        }

        const reload = lt(config.version, currentVersion);

        if (reload) {
          window.location.reload();
        }
      })
      .catch(console.error);

    fetchData();
  }, []);

  const fetchData = async (newOptions: Partial<Options> = {}) => {
    try {
      // Merge de las nuevas opciones con las viejas
      const options = Object.assign({}, currentOptions, newOptions);

      isLoading(true);

      // Hacemos la petición
      const res = await getAll('requests', options);

      // Recuperamos algunos datos de los resultados
      const { rows, count } = res as { rows: RawRequest[], count: number };

      const today = new Date(); // Obtiene la fecha actual

      const parseRows = rows.map(item => {
        const expiration: ExpirationInformation = {};

        const sessionDate = item.sessionDates[0]?.date;
        if (sessionDate && (item.estado_id === 4 || item.estado_id === 1)) {
          const startDate = new Date(sessionDate);
          const diffTime = Math.abs(today.getTime() - startDate.getTime());
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Diferencia en días

          if (diffDays <= 30) {
            expiration.colors = '#7ac29a'; // Verde
            expiration.diffDays = diffDays;
          } else if (diffDays < 60) {
            expiration.colors = '#f5d63d'; // Amarillo
            expiration.diffDays = diffDays;
          } else {
            expiration.colors = '#eb5e28', // Rojo
            expiration.diffDays = diffDays;
          }
        }

        return {
          ...item,
          ...expiration,
        } as RequestWithExpiration;
      });

      setItems(parseRows);
      setTotal(count);

      // Guardamos las opciones
      setCurrentOptions(options);
    } catch (err) {
      setItems([]);
      setTotal(0);
    } finally {
      // Terminamos la carga
      isLoading(false);
    }
  };

  const cancelRequest = async (id: number, confirmated: boolean) => {
    if (!confirmated) {
      return (
        setModalContent({
          title: '¿Estás seguro que deseas cancelar la solcitud?',
          id,
        }),
        setShowModal(true)
      );
    }
    setShowModal(false);
    try {
      const res = await deleteRequest(id);
      if (!res) {
        alert(true, 'Error! No se ha podido eliminar la solicitud #' + id);
      }

      const fixed = items.slice();
      const index = fixed.findIndex(item => item.id === id);
      fixed.splice(index, 1);
      return (
        setItems(fixed),
        setSuccessText('Éxito! La solicitud se ha eliminado correctamente.'),
        setSuccess(true)
      );
    } catch (err) {
      console.error(err);
    }
  };

  const downloadExcel = async () => {
    try {
      setIsLoadingExcel(true);

      const queryString = getQueryString(currentOptions);

      const res = await fetch(
        `${config.baseUrl}/requests/excel${queryString}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: getCookie('accessToken'),
          },
        },
      );

      const blob = await res.blob();

      const file = window.URL.createObjectURL(blob);

      window.location.assign(file);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingExcel(false);
    }
  };

  const changeOrder = (field: Options['sort']['field'], dir: Options['sort']['dir']) => {
    fetchData({
      sort: {
        field,
        dir,
      },
    });
  };

  const changePage = (page: number) => {
    fetchData({
      page,
    });
  };

  const changeFilter = (key: string, value: unknown) => {
    fetchData({
      [key]: value,
    });
  };

  const search = (query: string) => {
    fetchData({
      search: query,
    });
  };

  return (
    <div className="content">
      <AlertModal
        isOpen={showAlert}
        message={message}
        onClose={() => alert(false)}
      />
      <Modal isOpen={showModal} className="attach-modal modal-dialog-centered">
        <ModalHeader>{modalContent.title}</ModalHeader>
        <ModalFooter>
          <Button
            style={{ backgroundColor: 'red' }}
            onClick={() => cancelRequest(modalContent.id, true)}
          >
            Confirmar
          </Button>
          <Button color="primary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
      <Row>
        <Col md={12}>
          {c.getSetting<string>('autogestion.notification.habilitado') &&
          <Alert color="info"> {c.getSetting<string>('autogestion.notification')}</Alert>
          }
        </Col>
        <Col md={12}>
          <Search onSearch={search} currentQuery={currentOptions.search} />
          <Filters
            changeFilter={changeFilter}
            currentOptions={currentOptions}
          />
          <Card>
            <CardHeader>
              <Row>
                <Col md={8}></Col>
                <Col md={4} className="text-right">
                  <Link
                    to={{ pathname: '/solicitudes/step-insurer-selection' }}
                    className="btn btn-primary btn-round"
                  >
                    Nueva solicitud
                  </Link>
                </Col>
              </Row>
              {success && (
                <UncontrolledAlert color="success" className={success !== undefined ? '' : 'hidden'}>
                  { successText || 'Éxito! La solicitud se ha guardado correctamente.' }
                </UncontrolledAlert>
              )}
            </CardHeader>
            <CardBody>
              <Table
                items={items}
                isLoading={loading}
                onCancel={cancelRequest}
                changeOrder={changeOrder}
                currentOrder={currentOptions.sort}
              />
            </CardBody>
            <CardFooter className="py-4">
              <Row>
                <Col
                  sm={6}
                  className="d-flex justify-content-center d-sm-block mb-4 mb-md-0"
                >
                  <Button color="light" onClick={downloadExcel}>
                    {isLoadingExcel ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      <>Descargar Excel</>
                    )}
                  </Button>
                </Col>
                <Col
                  sm={6}
                  className="d-flex justify-content-center d-sm-block"
                >
                  <Pagination
                    page={currentOptions.page}
                    count={total}
                    onChangePage={changePage}
                  />
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default List;
