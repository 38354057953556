import React from "react"
import { Container, Row } from "reactstrap"
// used for making the prop types of this component
// import PropTypes from "prop-types"

function Footer (props) {

  return (
    <footer
      className={"footer" + (props.default ? " footer-default" : "")}
    >
      <Container fluid={props.fluid ? true : false}>
        <Row>
          <nav className="footer-nav">
            <ul>
              <li>
                © {new Date().getFullYear()}{" "}
                <a
                  className="font-weight-bold"
                  href="https://www.cokiba.org.ar/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  COKIBA
                </a>
              </li>
            </ul>
          </nav>
          <div className="credits ml-auto">
            <div className="copyright">
              <a
                href="https://www.qkstudio.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                QKStudio
              </a>
            </div>
          </div>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
