import React, { useState, ChangeEvent, useEffect } from 'react';
import { Button, FormGroup, Form, Input, Label } from 'reactstrap';
import { useAlert } from 'hooks';
import { useHistory } from 'react-router-dom';
import { setCookie, deleteCookie } from 'helpers';
import AlertModal from 'components/AlertModal';
import config from 'config';
import { useUser } from 'context';
import { valid, lt } from 'semver';

function Login() {
  const user = useUser();
  const [showAlert, message, alert] = useAlert(false, '');

  const [formData, setFormData] = useState({
    version: config.version,
    matriculate_id: '',
    password: '',
  });

  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const res = await fetch(`${ config.baseUrl }/auth/autogestion`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const payload = await res.json();

      if (!payload || !payload.success) {
        deleteCookie('accessToken');
        deleteCookie('sessionCookie');

        if (payload?.error?.name === 'SequelizeConnectionError') {
          alert(true, 'No se pudo autenticar la matrícula y contraseña ingresadas porque hay un problema de conexión' +
            '.Por favor, intente nuevamente más tarde');
        } else {
          alert(true, 'La matrícula y/o contraseña no son correctas. Por favor, verifique que los datos ingresados ' +
            'sean correctos e intente nuevamente');
        }
        setLoading(false);
        return;
      }

      user.setUser(payload.user);
      setCookie('sessionCookie', payload.sessionCookie, { days: 10 });
      setCookie('accessToken', payload.accessToken, { days: 10 });
      setLoading(false);
      history.push('/solicitudes');
    } catch (err) {
      console.error(err);
      alert(true, 'Se produjo un error al intentar validar sus datos. por favor, intente nuevamente más tarde');
      deleteCookie('accessToken');
      deleteCookie('sessionCookie');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch(`${ config.baseUrl }/system/version`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(res => {
        if (!res || !res.au) {
          return;
        }

        const currentVersion = valid(res.au);
        if (!currentVersion) {
          return;
        }

        const reload = lt(config.version, currentVersion);

        if (reload) {
          window.location.reload();
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <AlertModal isOpen={ showAlert } message={ message } onClose={ () => alert(false) } />
      <div style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        width: '320px',
      }}>
        <img src={ require('../logo-black.png') } alt="COKIBA" />
        <div className="small text-center font-weight-bold">
          v{ config.version }
        </div>
        <br /><br />
        <Form>
          <FormGroup>
            <Label htmlFor="matriculate_id">Matrícula</Label>
            <Input
              name="matriculate_id"
              type="text"
              placeholder="Ingresar el número de matrícula"
              className="text-center"
              onChange={ handleChange }
              disabled={ loading }
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="password">Contraseña</Label>
            <Input
              name="password"
              type="password"
              placeholder="Ingresar la contraseña"
              className="text-center"
              autoComplete="current-password"
              onChange={ handleChange }
              disabled={ loading }
            />
          </FormGroup>

          <Button
            color="primary"
            onClick={ handleSubmit }
            disabled={ loading }
          >
            {
              loading
                ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                : <>Iniciar Sesión</>
            }
          </Button>
        </Form>
      </div>
    </>
  );
}

export default Login;
