import React from 'react'

function Loader () {
  const style = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center'
  }

  return (
    <div style={ style }>
      <img src={ require('../logo-black.png') } alt="COKIBA" /><br />
      <div className="spinner-border text-primary m-4" style={{ width: '3rem', height: '3rem' }} role="status">
        <span className="sr-only">Cargando...</span>
      </div>
    </div>
  )
}

export default Loader