import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Table,
  Button,
} from 'reactstrap';
import { getOne } from 'services/ApiService';
import { useAlertPlus } from 'hooks';
import AlertModal from 'components/AlertModal';
import Attachments from 'components/Requests/Attachments';
import Required from 'components/Required';
import DatePicker from 'components/DatePicker';
import { UserContext } from 'context/UserContext';
import { useHistory } from 'react-router-dom';
import config from '../../config';
import moment from 'moment';

function Edit() {
  const { id } = useParams();

  const location = useLocation();
  const diffDays = location.state ? location.state.diffDays : null;

  const history = useHistory();

  const user = useContext(UserContext);

  const [formData, setFormData] = useState({
    insurer_id: null,
    status_id: null,
    authorization_id: null,
    recipient_id: null,
    recipient_name: null,
    recipient_age: null,
    prescription_date: null,
    diagnosis: null,
    impairment: false,
    sessions: [],
    practice_id: null,
    additional_id: null,
    files: {},
    previously_authorized: null,
    effector_id: null,
    prescriber_id: user.id,
    exceptional: false,
    exceptional_text: null,
    rebilling: false,
  });

  const [files, setFiles] = useState({});

  const [showAlert, message, alertCallback, alert] = useAlertPlus('');
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    getOne('requests', id)
      .then((res) => {
        if (!res.id) {
          alert(
            'Se produjo un error al intentar recuperar la información de la presentación. Por favor, intente nuevamente más tarde',
          );
          return;
        }

        const data = formData;

        // Parseamos las fechas en objetos Date para el DatePicker
        if (res.fecha_prescripcion_medica) {
          data.prescription_date = moment(res.fecha_prescripcion_medica, 'YYYY-MM-DD').toDate();
        }

        if (res.authorization_date) {
          data.authorization_date = moment(res.authorization_date).toDate();
        }

        // Parseamos las fechas en objetos Date para el DatePicker
        res.sessionDates.forEach((session) => {
          data.sessions.push({
            date: moment(session.date).toDate(),
            authorization: session.approvalNumber,
          });
        });

        data.previously_authorized = res.aprobacion_previa;

        if (res.numero_afiliado) {
          data.recipient_age = res.affiliate.edad;
          data.recipient_id = res.affiliate.numero_afiliado;
          data.recipient_name = res.affiliate.nombre_apellido;
        }

        data.recipient_validated = res.recipient_validated;

        data.diagnosis = res.diagnostico;

        data.impairment = res.prestacion_discapacidad;

        data.practice_id = res.idTipoPractica;
        data.practice_text = res.tipo_practica;
        data.additional_id = res.idPracticaAdicional;
        data.additional_text = res.practica_adicional;

        data.authorization_id = res.numero_aprobacion;

        data.insurer_id = res.ooss_id;
        data.status_id = res.estado_id;

        if (res.ooss) {
          data.insurer_external_id = res.ooss.numero;
          data.insurer_shortname = res.ooss.codigo;
          data.insurer_fullname = res.ooss.nombre;
        }

        data.max_sessions = res.cantidad_sesiones
          ? parseInt(res.cantidad_sesiones)
          : 5;

        if (res.attachs && Array.isArray(res.attachs)) {
          const newFiles = {};
          res.attachs.forEach((file) => {
            newFiles[file.tipo] = {
              type:
                file.tipo_archivo === 'pdf' ? 'application/pdf' : 'image/jpeg', // En realidad aparte de application/pdf, el otro tipo no importa
              url: config.baseUrl + '/' + file.url,
            };
          });

          setFiles(newFiles);
        }

        data.observations = res.observations;

        data.exceptional = res.exceptional;
        data.exceptional_text = res.exceptional_text;

        data.rebilling = res.es_refacturacion;

        // Actualizamos la información del estado
        setFormData(data);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="content">
      <Row>
        <Col sm={12}>
          <AlertModal
            isOpen={showAlert}
            message={message}
            onClose={alertCallback}
          />
          <Card>
            <CardHeader>
              <Row style={{ alignItems: 'center' }}>
                <Col sm={8}>
                  <CardTitle tag="h5">
                    Presentación 1 #{id}
                    {formData.insurer_shortname
                      ? ' - ' + formData.insurer_shortname
                      : ''}{' '}
                    {diffDays != null &&
                      (diffDays >= 60
                        ? ' - [Solicitud vencida]'
                        : ` - [Restan ${
                            60 - diffDays
                          } días para que se venza la solicitud]`)}
                    {
                      (formData?.insurer_id == 55 && formData?.status_id == 10)
                        ? '- Se eliminan las sesiones para retornar el cupo al afiliado'
                        : ''
                    }
                  </CardTitle>
                </Col>
                <Col sm={4} className="text-right">
                  <Button
                    className="btn-round"
                    color="primary"
                    onClick={() => history.push('/solicitudes')}
                  >
                    Volver
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {isLoading ? null : (
                <Form>
                  <FormGroup>
                    <Row form>
                      <Col sm={6}>
                        <Label for="authorization_id">
                          Número de Aprobación <Required />
                        </Label>
                        <Input
                          id="authorization_id"
                          name="authorization_id"
                          defaultValue={formData.authorization_id ?? ''}
                          disabled={true}
                        />
                      </Col>
                      <Col sm={6}>
                        <Label for="authorization_date">
                          Fecha de Aprobación
                        </Label>
                        <DatePicker
                          id="authorization_date"
                          name="authorization_date"
                          selected={formData.authorization_date ?? ''}
                          dateFormat="dd/MM/yyyy"
                          locale="es"
                          placeholderText="Fecha de Aprobación"
                          className="date-picker form-control"
                          disabled={true}
                          autoComplete="off"
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Label for="recipient_id">
                      Número de Afiliado <Required />
                    </Label>
                    <Input
                      id="recipient_id"
                      name="recipient_id"
                      defaultValue={formData.recipient_id ?? ''}
                      disabled={true}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="recipient_name">
                      Nombre del Afiliado <Required />
                    </Label>
                    <Input
                      id="recipient_name"
                      name="recipient_name"
                      defaultValue={formData.recipient_name ?? ''}
                      disabled={true}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="recipient_age">
                      Edad <Required />
                    </Label>
                    <Input
                      id="recipient_age"
                      name="recipient_age"
                      defaultValue={formData.recipient_age ?? ''}
                      disabled={true}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="prescription_date">
                      Fecha Prescripción Médica <Required />
                    </Label>
                    <DatePicker
                      id="prescription_date"
                      name="prescription_date"
                      selected={formData.prescription_date ?? ''}
                      dateFormat="dd/MM/yyyy"
                      locale="es"
                      placeholderText="Fecha Prescripción"
                      className="date-picker form-control"
                      disabled={true}
                      autoComplete="off"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="diagnosis">
                      Diagnóstico <Required />
                    </Label>
                    <Input
                      id="diagnosis"
                      name="diagnosis"
                      type="textarea"
                      defaultValue={formData.diagnosis ?? ''}
                      disabled={true}
                    />
                  </FormGroup>
                  <FormGroup style={{ paddingLeft: '1.5rem' }}>
                    <Input
                      id="impairment"
                      name="impairment"
                      type="checkbox"
                      checked={formData.impairment || false}
                      disabled={true}
                    />
                    <Label
                      for="impairment"
                      style={{ marginBottom: 0, verticalAlign: 'bottom' }}
                    >
                      Es discapacidad
                    </Label>
                  </FormGroup>
                  <FormGroup style={{ paddingLeft: '1.5rem' }}>
                    <Input
                      id="rebilling"
                      name="rebilling"
                      type="checkbox"
                      checked={formData.rebilling || false}
                      disabled={true}
                    />
                    <Label
                      for="rebilling"
                      style={{ marginBottom: 0, verticalAlign: 'bottom' }}
                    >
                      Es refacturación
                    </Label>
                  </FormGroup>
                  <FormGroup style={{ paddingLeft: '1.5rem' }}>
                    <Input
                      id="exceptional"
                      name="exceptional"
                      type="checkbox"
                      checked={formData.exceptional || false}
                      disabled={true}
                    />
                    <Label
                      for="exceptional"
                      style={{ marginBottom: 0, verticalAlign: 'bottom' }}
                    >
                      Es excepcional
                    </Label>
                  </FormGroup>
                  <FormGroup
                    row
                    className={formData.exceptional ? 'd-block' : 'd-none'}
                  >
                    <Col>
                      <Input
                        id="exceptional_text"
                        name="exceptional_text"
                        type="textarea"
                        value={formData.exceptional_text ?? ''}
                        disabled={true}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Label>Fechas de sesiones</Label>
                    <Table
                      className="align-items-center table-flush mb-0 col-sm-6"
                      style={{ fontSize: '.8rem' }}
                      responsive={false}
                    >
                      <thead>
                        <tr>
                          <th>FECHA SESIÓN</th>
                          {formData.insurer_id === 57 ? ( // Swiss
                            <th>AUTORIZACIÓN</th>
                          ) : null}
                        </tr>
                      </thead>
                      <tbody>
                        {formData.sessions && Array.isArray(formData.sessions)
                          ? formData.sessions
                              .sort((a, b) => a.date - b.date)
                              .map(({ date, authorization }, i) => (
                                <tr key={i}>
                                  <td>
                                    {new Date(date).toLocaleDateString('es')}
                                  </td>
                                  {formData.insurer_id === 57 ? ( // Swiss
                                    <td>{authorization ?? ' - '}</td>
                                  ) : null}
                                </tr>
                              ))
                          : null}
                      </tbody>
                    </Table>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="6">
                      <Label for="practice_id">
                        Tipo de práctica <Required />
                      </Label>
                      <Input
                        id="practice_id"
                        name="practice_id"
                        type="text"
                        defaultValue={formData.practice_text ?? ''}
                        disabled={true}
                      ></Input>
                    </Col>
                    {formData.additional_text ? (
                      <Col md="6">
                        <Label for="addtional_id">
                          Práctica adicional <Required />
                        </Label>
                        <Input
                          id="additional_id"
                          name="additional_id"
                          type="text"
                          defaultValue={formData.additional_text}
                          disabled={true}
                        ></Input>
                      </Col>
                    ) : null}
                  </FormGroup>
                  {Array.isArray(files) && files.length ? (
                    <Attachments
                      insurer_id={formData.insurer_id}
                      files={files}
                      sessions={formData.sessions.length}
                      previously_authorized={formData.previously_authorized}
                      setFiles={setFiles}
                      editable={false}
                      impairment={formData.impairment}
                      setLoading={setLoading}
                      exceptional={formData.exceptional}
                    />
                  ) : null}
                </Form>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Edit;
