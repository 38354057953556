import React, { useState } from "react"
import { Table, Card } from "reactstrap"
import { Link } from "react-router-dom"

import status from 'services/status'
import Loader from "components/TableLoader"

import './Table.sass'

function ContentTable (props) {

  const { items, isLoading, filtered, currentOrder, changeOrder } = props
  
  const [activeRow, setActiveRow] = useState(-1)

  const getEstado = id => status[id] || 'Borrador'

  const getTogglerClass = (id) => {
    if (activeRow === id) {
      return 'fa fa-caret-down'
    }
    return 'fa fa-caret-right'
  }

  const getIconClassBySorted = (field) => {
    if (currentOrder.field === field) {
      if (currentOrder.dir === -1) {
        return 'fa fa-caret-down'
      } else {
        return 'fa fa-caret-up'
      }
    }

    return 'fas fa-sort'
  }

  const setNewOrder = (field) => {
    // Si el campo elegido ya está seleccionado, sólo cambiamos el orden
    if (currentOrder && currentOrder.field === field) {
      changeOrder(
        currentOrder.field,
        currentOrder.dir === 1 ? -1 : 1
      )
    // Si es un nuevo campo, lo indicamos como seleccionado y reseteamos el orden
    // a ascendente
    } else {
      changeOrder(field, 1)
    }
  }

  const toggleExpand = (id) => {
    if (activeRow === id) {
      return setActiveRow(-1)
    }
    setActiveRow(id)
  }

  const columns = [
    {
      label: 'N°',
      name: 'id',
      sort: true,
      value: (row) => `#${ row.id }`
    },
    {
      label: 'OOSS',
      name: 'ooss_id',
      sort: true,
      value: (row) => row.ooss ? row.ooss.codigo : ''
    },
    {
      label: 'Año',
      name: 'yearPeriod',
      sort: true,
      value: (row) => row.yearPeriod
    },
    {
      label: 'Mes',
      name: 'monthPeriod',
      sort: true,
      value: (row) => row.monthPeriod
    },
    {
      label: 'Concepto',
      name: 'concept',
      sort: true,
      value: (row) => row.concept
    },
    {
      label: 'Importe',
      name: 'amount',
      sort: true,
      value: (row) => `${row.amount}`
    },
    {
      label: 'Estado',
      name: 'status',
      sort: true,
      value: (row) => row.status
    },
    {
      label: 'Acciones',
      name: 'actions',
      value: (row) => row.status === 'Pendiente' || row.status === 'Observado'
        ? <Link to={`/facturacion/${row.id}/edit`}>
            <i className="fa fa-edit edit-button"></i>
          </Link>
        : <Link to={`/facturacion/${row.id}`}>
            <i className="fa fa-eye show-button"></i>
          </Link>
    }
  ]

  const mobileColumns = [
    {
      label: 'N°',
      name: 'id',
      sort: true,
      value: (row) => row.id
    },
    {
      label: 'OOSS',
      name: 'ooss.codigo',
      sort: true,
      value: (row) => row.ooss ? row.ooss.codigo : ''
    },
    {
      label: 'Año',
      name: 'yearPeriod',
      sort: true,
      value: (row) => row.yearPeriod
    },
    {
      label: 'Mes',
      name: 'monthPeriod',
      sort: true,
      value: (row) => row.monthPeriod
    },
    {
      label: 'Importe',
      name: 'amount',
      sort: true,
      value: (row) => `${row.amount}`
    }
  ]

  return (
    <>
      {/* DESKTOP - responsive={true} generates overflow */}
      <Table className='align-items-center table-flush table--desktop' responsive={ false }>
        <thead className="thead-primary table--head">
          <tr className='table--head__tr'>
            {
              // Cabecera
              columns.map((column) => {
                if (!column.sort) {
                  return <th key={column.name} scope='col' className={column.classes ?? ''}>{ column.label }</th>
                }
  
                return (
                  <th key={column.name} scope='col' className={ `clickable ${column.classes ?? ''}` } onClick={ () => setNewOrder(column.name) }>
                    { column.label }&nbsp;
                    { <i className={ getIconClassBySorted(column.name) }></i> }
                  </th>
                )
              })
            }
          </tr>
        </thead>
        <tbody className='table--body'>
        {
          // Datos
          !isLoading
          ? Array.isArray(items) && items.length
            ? items.map(row =>
              <tr key={row.id}>
                { columns.map(column => <td key={ column.name } className={ column.classes ?? '' } >{ column.value(row) }</td>) }
              </tr>
            )
            : <tr>
                <td colSpan={ columns.length } className='text-center'>
                  <span>No se encontraron solicitudes</span>
                </td>
              </tr>
          : <tr>
              <td colSpan={ columns.length } className='text-center'>
                <Loader />
              </td>
            </tr>
        }
        </tbody>
      </Table>
      {/* MOBILE */}
      <Table className='align-items-center d-sm-none table-borderless' responsive={false}>
        <thead className="thead-primary table__mobile--head">
          <tr className='table__mobile--head__tr'>
            {
              mobileColumns.map(({ name }) => (
                <th key={name}>
                  <span>{ name }</span>
                </th>
              ))
            }
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody className='table__mobile--body'>
          {
            !isLoading && Array.isArray(items) ?
            items.length ?
            items.map(row => {
              return (
                <React.Fragment key={row.id}>
                  <tr>
                    <td onClick={ () => toggleExpand(row.id) }>
                      <i className={`table__mobile--toggler ${getTogglerClass(row.id)} pr-3`}/> #{ row.id }
                    </td>
                    <td>{ getEstado(row.estado_id) }</td>
                    <td className='text-left table__mobile--body__actions'>
                        <Link to={ `/solicitudes/${row.id}` }>
                          <i className="fa fa-eye show-button"></i>
                        </Link>
                    </td>
                  </tr>
                  <tr className={activeRow === row.id ? '' : 'hidden'}>
                    <td colSpan={mobileColumns.length + 1}>
                      <Card>
                        <Table>
                          <tbody>
                            <tr>
                              <td><strong>OBRA SOCIAL</strong></td>
                              <td>{ row.ooss ? row.ooss.codigo : '-' }</td>
                            </tr>
                            <tr>
                              <td><strong>N° APROBACIÓN</strong></td>
                              <td>{row.numero_aprobacion || '-'}</td>
                            </tr>
                            <tr>
                              <td><strong>N° AFILIADO</strong></td>
                              <td>{row.numero_afiliado || '-'}</td>
                            </tr>
                            <tr>
                              <td><strong>ACTUALIZADO</strong></td>
                              <td>{new Date(row.updatedAt).toLocaleString('es')}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Card>
                    </td>
                  </tr>
                </React.Fragment>
              )
            }) :
            // content empty
            <tr>
              <td colSpan={mobileColumns.length + 1} className='text-center'>
                <span>{filtered ? 'No se encontraron facturas.' : 'Aún no hay facturas solicitadas.'}</span>
              </td>
            </tr>
            :
            // loading state
            <tr>
              <td colSpan={mobileColumns.length + 1} className='text-center'>
                <Loader />
              </td>
            </tr>
          }
        </tbody>
      </Table>
    </>
  )
}

export default ContentTable
