import React, { ChangeEvent, useState } from 'react';
import { Col, Label, InputGroup, Input, InputGroupText } from 'reactstrap';

interface Afiliado2Props {
  disabled: boolean;

  onChange: (recipient: string[]) => void;
}

export default function Afiliado3(props: Afiliado2Props) {
  const { disabled = false, onChange } = props;

  const [recipient, setRecipient] = useState({
    id: '',
    sub1: '',
    sub2: '',
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newRecipient = {
      ...recipient,
      [event.target.name]: event.target.value,
    };

    setRecipient(newRecipient);

    // Componemos el número de afiliado en un sólo campo
    onChange([newRecipient.id, newRecipient.sub1.padStart(2, '0'), (newRecipient.sub2 ? newRecipient.sub2 : '0')]);
  };

  return (
    <Col sm={ 12 } md={ 6 }>
      <Label for="id">N° de Afiliado</Label>
      <InputGroup>
        <Input
          id="id"
          name="id"
          type="number"
          step={ 1 }
          min={ 1 }
          max={ 9999999999 }
          pattern="\d+"
          style={{ width: '30%', textAlign: 'right' }}
          value={ recipient.id }
          onChange={ handleInputChange }
          disabled={ disabled }
        />
        <InputGroupText style={{
          backgroundColor: 'transparent',
          borderRadius: 0,
          borderLeft: 'none',
          borderRight: 'none',
        }}>
          -
        </InputGroupText>
        <Input
          id="sub1"
          name="sub1"
          type="number"
          step={ 1 }
          min={ 1 }
          max={ 99 }
          pattern="\d+"
          value={ recipient.sub1 }
          onChange={ handleInputChange }
          disabled={ disabled }
          style={{ borderRight: 'none', borderRadius: 0 }}
        />
        <InputGroupText style={{
          backgroundColor: 'transparent',
          borderRadius: 0,
          borderLeft: 'none',
          borderRight: 'none',
        }}>
          -
        </InputGroupText>
        <Input
          id="sub2"
          name="sub2"
          type="number"
          step={ 1 }
          min={ 1 }
          max={ 99 }
          pattern="\d+"
          value={ recipient.sub2 }
          onChange={ handleInputChange }
          disabled={ disabled }
        />
      </InputGroup>
    </Col>
  );
}
