import React from 'react';
import { default as ReactDatePicker, registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';

registerLocale('es', es);

export interface DatePickerChangeEvent {
  target: {
    name: string;
    type: 'datepicker';
    value: Date | null;
  } | HTMLInputElement;
}

interface DatePickerPros {
  onChange: (date: Date | null) => void;
  [key: string]: unknown;
}

export default function DatePicker(props: DatePickerPros) {
  const { onChange, ...inputProps } = props;

  const handleChange = (date: Date | null) => {
    if (!onChange) {
      return;
    }

    return onChange(date);
  };

  return (
    <ReactDatePicker
      { ...inputProps }
      locale="es"
      onChange={date => handleChange(date)}
    />
  );
}
