import React, { useState, useEffect, Fragment } from 'react';
import { Observation } from 'types/Request';
import { Input, Button, Spinner } from 'reactstrap';

interface ObservationListProps {
  observations: Observation[];
  disabled: boolean;
  createObservation: (observation: string) => Promise<boolean>;
}

export default function ObservationList(props: ObservationListProps) {
  const { observations, disabled, createObservation } = props;

  const [isDisabled, setIsDisabled] = useState(disabled);
  const [isLoading, setIsLoading] = useState(false);

  const [observation, setObservation] = useState('');

  // Si no hay datos, no renderizamos nada
  if (!Array.isArray(observations)) {
    return null;
  }

  const submitObservation = async () => {
    const success = await createObservation(observation);
    if (success) {
      setObservation('');
    }

    setIsLoading(false);
  };

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  return (
    <>
      {
        observations.map(obs => (
          <p className="mt-2" key={ obs.id }>
            {
              obs.source === 'auditor'
                ? <i className="fas fa-angle-double-left"></i>
                : <i className="fas fa-angle-double-right"></i>
            }
            &nbsp;
            {
              obs.text
                .split(/\r?\n/)
                .map((line, idx) => <Fragment key={`${ obs.id }_${ idx }`}>{ line }<br /></Fragment>)
            }
            &nbsp;
            <small>{ new Date(obs.createdAt).toLocaleString('es') }</small>
          </p>
        ))
      }
      <Input
        autoComplete="off"
        className="form-control-alternative"
        type="textarea"
        rows="4"
        name="observation"
        placeholder="Agregar observación"
        value={ observation }
        onChange={ e => setObservation(e.target.value) }
        disabled={ isDisabled || isLoading }
      />
      <div className="update ml-auto mr-auto">
        <Button
          className="btn-round"
          color="success"
          size="md"
          onClick={ () => submitObservation() }
          disabled={ isDisabled || isLoading || observation.length === 0 }
        >
          {
            isLoading
              ? <><Spinner size="sm" />&nbsp;</>
              : null
          }
          Agregar Observación
        </Button>
      </div>
    </>
  );
}
