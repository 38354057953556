import React, { useState, useEffect } from 'react';

import { Row, Col, Input, Button, FormGroup, Label } from 'reactstrap';

import Required from 'components/Required';
import AttachModal from 'components/AttachModal';
import config from '../../config';
import { bytesToSize, getRequiredAttachs } from 'helpers';
import { attachs as attachsLabels } from 'services/attachs';
import { RequestFiles, Request } from 'types/Request';

// Mime types aceptados por la aplicación
const accept = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];

interface AttachmentsProps {
  files: RequestFiles;
  setFiles: (files: RequestFiles) => void;
  disabled?: boolean;
  editable?: boolean;
  setLoading?: (loading: boolean) => void;
  previouslyAuthorized: boolean;
  formData: Request
}

export default function Attachments(props: AttachmentsProps) {
  const {
    files,
    setFiles,
    disabled = false,
    editable = true,
    setLoading: setExternalLoading,
    previouslyAuthorized = false,
    formData,
  } = props;
  const [attachModal, setAttachModal] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [required, setRequired] = useState<(keyof RequestFiles)[]>([]);
  const [sizeError, setSizeError] = useState<string | null>(null);
  const [emptyError, setEmptyError] = useState<string | null>(null);
  const [typeError, setTypeError] = useState<string | null>(null);

  // Parámetros
  const [impairment, setImpairment] = useState(false);

  const handleFileChange = (name: string, fileList: FileList | null) => {
    // Si no se seleccionó ningún archivo, no hacemos nada
    if (!fileList || !fileList.length) {
      return;
    }

    if (!accept.includes(fileList[0].type)) {
      setTypeError(name);
      // Reseteamos el file input
      const fileInput = document.getElementById(`file_${ name }`) as HTMLInputElement;
      if (fileInput) {
        fileInput.value = '';
      }
      return;
    }

    // Controlamos el tamaño del archivo
    if (fileList[0].size > config.maxFileSize) {
      setSizeError(name);
      // Reseteamos el file input
      const fileInput = document.getElementById(`file_${ name }`) as HTMLInputElement;
      if (fileInput) {
        fileInput.value = '';
      }
      return;
    }

    if (fileList[0].size === 0) {
      setEmptyError(name);
      // Reseteamos el file input
      const fileInput = document.getElementById(`file_${ name }`) as HTMLInputElement;
      if (fileInput) {
        fileInput.value = '';
      }
      return;
    }

    setLoading(true);
    if (setExternalLoading) {
      setExternalLoading(true);
    }
    setFiles({ ...files, [name]: fileList[0] });

    setSizeError(null);
    setTypeError(null);

    setLoading(false);
    if (setExternalLoading) {
      setExternalLoading(false);
    }
  };

  const handleFilePreview = (name: keyof RequestFiles) => {
    try {
      setLoading(true);
      if (setExternalLoading) {
        setExternalLoading(true);
      }

      // Si no hay una url definida porque no viene de la BBDD o porque todavía
      // no se previsualizó, creamos una
      // @ts-ignore
      if (!files[name].url) {
        // @ts-ignore
        files[name].url = URL.createObjectURL(files[name]);
      }

      // Los pdfs los abrimos en una nueva pestaña/ventana
      // @ts-ignore
      if (files[name].type === 'application/pdf') {
        // @ts-ignore
        window.open(files[name].url);
        return;
      }

      // Para todo lo demás utilizamos el modal
      setAttachModal({
        show: true,
        // @ts-ignore
        url: files[name]!.url,
        title: attachsLabels[name],
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      if (setExternalLoading) {
        setExternalLoading(false);
      }
    }
  };

  const handleFileReplace = (name: keyof RequestFiles) => {
    const fileInput = document.getElementById(`file_${ name }`) as HTMLInputElement;
    fileInput.click();
  };

  const handleFileRemove = (name: keyof RequestFiles) => {
    try {
      setLoading(true);

      // Reseteamos el file input
      const fileInput = document.getElementById(`file_${ name }`) as HTMLInputElement;
      if (fileInput) {
        fileInput.value = '';
      }

      // Si tiene una vista previa generada, la borramos
      // @ts-ignore
      if (files[name]?.url && files[name]?.url.substring(0, 4) === 'blob') {
        // @ts-ignore
        URL.revokeObjectURL(files[name]!.url);
      }

      // Quitamos el archivo del state
      setFiles({
        ...files,
        [name]: undefined,
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getRequiredValue = (key: keyof RequestFiles) => {
    // COMEI y CASA
    if ((formData.insurer_id === 22 || formData.insurer_id === 47) && key === 'historia_clinica') {
      return false;
    }

    if (formData.insurer_id == 57 && impairment && key === 'frente_orden') {
      return false;
    }

    // Dorso orden opcional excepto COMEI
    if (key === 'dorso_orden' && formData.insurer_id != 22) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    setLoading(disabled);
  }, [disabled]);

  useEffect(() => {
    if (!editable) {
      setRequired(Object.keys(files) as (keyof RequestFiles)[]);
      return;
    }

    let newRequired = required;

    switch (formData.insurer_id) {
      case 86: { // OPDEA
        const bonos = [];
        for (let i = 1; i <= formData.sessions.length; i++) {
          bonos.push(`bono_${ i }`);
        }

        newRequired = [
          ...getRequiredAttachs(formData.insurer_id),
          ...bonos,
        ];
        break;
      }
      default:
        newRequired = getRequiredAttachs(formData.insurer_id);
    }

    newRequired = newRequired.filter(r => r !== 'certificado_discapacidad');
    if (impairment) {
      if (formData.insurer_id == 74) { // Federada
        newRequired.push('planilla_asistencia');
      } else {
        newRequired.push('certificado_discapacidad');
      }

      if (formData.insurer_id === 22 || formData.insurer_id === 57) { // COMEI y Swiss Medical
        attachsLabels['certificado_discapacidad'] = 'Autorización del tratamiento de discapacidad';
      } else if (formData.insurer_id === 26 || formData.insurer_id === 39) { // AMFFA y COESBA
        attachsLabels['certificado_discapacidad'] = 'Certificado de Discapacidad (CUD)';
      } else {
        attachsLabels['certificado_discapacidad'] = 'Certificado de discapacidad';
      }

      if (formData.insurer_id === 57) { // Swiss Medical
        newRequired.push('planilla_asistencia');
      }
    }

    if (formData.insurer_id === 44) { // OSAM
      newRequired.push('planilla_asistencia');
    }

    // Excepto para el caso de discapacidad AMFFA y Swiss todas las otras autorizaciones previa requieren autorización
    // de la obra social. Dada la complejidad de Medife se trata por separado
    if (previouslyAuthorized &&
      !(formData.insurer_id === 57 && impairment) &&
      !(formData.insurer_id === 26 && impairment) &&
      formData.insurer_id != 55
    ) {
      newRequired.unshift('autorizacion');
    } else if (formData.insurer_id == 55) { // Medife
      attachsLabels['autorizacion'] = 'Autorización OOSS';

      if (formData.extended_quota) {
        newRequired.unshift('autorizacion');
        attachsLabels['autorizacion'] = 'Autorización de cupo';
      } else if (formData.additional_id == 11) { // Internación / Domicilio
        newRequired.unshift('autorizacion');
      } else if ( // RPG sólo con planes bronce y plata
        formData.practice_id == 9 && // RPG
        (
          formData.recipient_plan.toUpperCase().includes('COBRE') || // Plan cobre
          formData.recipient_plan.toUpperCase().includes('NIQUEL') || // Plan niquel
          formData.recipient_plan.toUpperCase().includes('BRONCE') || // Plan bronce
          formData.recipient_plan.toUpperCase().includes('TITANIO') || // Plan titanio
          formData.recipient_plan.toUpperCase().includes('PLATA') // Plan plata
        )
      ) {
        newRequired.unshift('autorizacion');
      } else if (formData.practice_id && ([10].includes(formData.practice_id) || formData.impairment)) { // Drenaje Linfátivo y discapacidad
        newRequired.unshift('autorizacion');
      } else if (formData.practice_id == 14) { // Internación
        newRequired.unshift('indicacion_medica');

        newRequired.unshift('autorizacion');
        attachsLabels['autorizacion'] = 'Autorización Internación';
      }
    }

    // Para Comei se necesita autorización si hay id de autorización por incremento de cupo
    if (formData.insurer_id === 22 && formData.authorization_id) {
      newRequired.unshift('autorizacion');
    }

    // Para AMFFA se necesita autorización para la práctica RPG
    if (formData.insurer_id === 26 && formData.practice_id == 9) {
      newRequired.unshift('autorizacion');
    }

    if (formData.exceptional) {
      newRequired.push('exceptional');
    }

    if (formData.rebilling) {
      newRequired.push('nota_debito');
    }

    if (formData.insurer_id == 57 && formData.practice_id == 40) {
      newRequired.push('constancia_deportiva');
    }

    setRequired(newRequired);
  }, [
    formData.insurer_id,
    formData.sessions.length,
    previouslyAuthorized,
    impairment,
    formData.rebilling,
    formData.exceptional,
    formData.practice_id,
    formData.additional_id,
  ]);

  useEffect(() => {
    switch (formData.insurer_id) {
      case 22: // COMEI
        setImpairment(formData.impairment);
        break;
      case 26: // AMFFA
        setImpairment(formData.impairment);
        break;
      case 74: // Federada
        setImpairment(formData.practice_id == 14 || formData.additional_id == 14);
        break;
      default:
        setImpairment(formData.impairment);
    }
  }, [
    formData.insurer_id,
    formData.previously_authorized,
    formData.practice_id,
    formData.additional_id,
    formData.impairment,
  ]);

  return (
    <>
      <FormGroup>
        <Label>
          Adjuntos<br />
          <small className='mt-2 text-muted'>
            Tamaño máximo por archivo: <strong>{ bytesToSize(config.maxFileSize) }</strong>
          </small>
        </Label>
        <Row>
          {
            required.map(key => (
              <Col sm={ 6 } key={ key } className="mt-2">
                <label>{ attachsLabels[key] } { getRequiredValue(key) ? <Required /> : null}</label>
                <div className={ files[key] ? 'd-block' : 'd-none' }>
                  <Button color="primary" onClick={ () => handleFilePreview(key) } disabled={ isLoading }>
                    Ver adjunto
                  </Button>
                  {
                    editable
                      ? <>
                          <Button color="warning" onClick={ () => handleFileReplace(key) } disabled={ isLoading }>
                          Reemplazar
                          </Button>
                          <Button onClick={ () => handleFileRemove(key) } disabled={ isLoading }>
                          Eliminar
                          </Button>
                        </>
                      : null
                  }
                </div>
                {
                  editable
                    ? <div className={`custom-file ${ files[key] ? 'd-none' : '' }`}>
                        <Input
                          id={`file_${ key }`}
                          name={`file_${ key }`}
                          data-key={ key }
                          type="file"
                          accept={ accept.join(', ') }
                          onChange={ event => handleFileChange(key, event.target.files) }
                          className="custom-file-input overflow-hidden"
                          disabled={ isLoading }
                          required={ getRequiredValue(key) }
                        />
                        <label className="custom-file-label" htmlFor={`file_${ key }`}>Elegir archivo...</label>
                        <label
                          className={`required-text mt-2 ${ sizeError !== key ? 'hidden' : ''}`}
                          style={{ fontSize: '80%' }}
                        >
                          El tamaño del archivo no debe superar los 3 MB</label>
                        <label
                          className={`required-text mt-2 ${ emptyError !== key ? 'hidden' : ''}`}
                          style={{ fontSize: '80%' }}
                        >
                          El archivo que está intentando subir está vacio. Verifique que no sea un enlace directo y que
                          sea válido
                        </label>
                        <label
                          className={`required-text mt-2 ${ typeError !== key ? 'hidden' : ''}`}
                          style={{ fontSize: '80%' }}
                        >
                          El formato del archivo no está soportado. Únicamente se pueden adjuntar imágenes JPG y PNG o
                          documentos PDF
                        </label>
                      </div>
                    : null
                }
              </Col>
            ))
          }
        </Row>
      </FormGroup>

      <AttachModal data={ attachModal } onClose={ () => setAttachModal({ ...attachModal, show: false }) } />
    </>
  );
}
