import React from 'react'
import './TableLoader.sass'

// @component
function Loader () {

  return (
    <>
      <div className='table-loader'>
        <div />
        <div />
        <div />
        <div />
      </div>
    </>
  )
}

export default Loader