import { APIResponse } from 'types/Request';
import { getCookie } from 'helpers';
import config from 'config';
import { Expiration } from 'types/Expiration';

export async function getExpirations(): Promise<Expiration[] | undefined> {
  try {
    const url = new URL(`${ config.baseUrl }/matriculates/vencimientos`);

      type Response = APIResponse<Expiration[]>

      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getCookie('accessToken'),
        },
      });

      if (response.status !== 200) {
        return;
      }

      const content = await response.json() as Response;
      const { payload } = content;

      return payload;
  } catch (err) {
    throw new Error('Ocurrió un problema inesperado al momento de intentar recuperar las gráficas. ');
  }
}
