import React from 'react'

import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter
} from "reactstrap"

function ConfirmModal (props) {
  const { isOpen, title, onClose, onConfirm, action } = props

  const close = () => {
    return onClose()
  }

  const confirm = () => {
    return onConfirm(action)
  }
  return (
    <>
      <Modal isOpen={isOpen} className='modal-dialog-centered'>
        <ModalHeader>
          {
             title
          }
        </ModalHeader>
        <ModalFooter>
          <Button color='success' onClick={() => confirm()}>Confirmar</Button>
          <Button color="primary" onClick={() => close()}>Cancelar</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ConfirmModal