import React, { useEffect, useRef, useState, useContext } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { UserContext } from 'context';
import { initialize } from 'services/ApiService';
import { getQueryParam, getCookie, setCookie, deleteCookie } from 'helpers';
import Navbar from 'components/Navbars/Navbar.js';
import Footer from 'components/Footer/Footer.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import routes from 'routes.js';
import Loader from 'views/Loader';

function Dashboard(props) {
  const { setUser } = useContext(UserContext);

  const history = useHistory();
  const mainPanel = useRef();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const tokenParam = getQueryParam('token');
    const tokenCookie = getCookie('accessToken');

    // Si no tenemos ningún token, nosvi
    if (!tokenCookie && !tokenParam) {
      deleteCookie('sessionCookie');
      deleteCookie('accessToken');
      history.replace('/login');
      setLoading(false);
      return;
    }

    // Recuperamos el token ya sea de los parámetros o de la cookie
    const token = tokenParam || tokenCookie;

    initialize(token)
      .then(res => {
      // Si no se devolvió un usuario, falló la autenticación
        if (!res.user) {
          deleteCookie('sessionCookie');
          deleteCookie('accessToken');
          history.replace('/login');
          console.debug('Autenticación fallida', res);
          return;
        }

        // Si se devolvió usuario, guardamos el token la cookie
        // y la información del usuario en el estado
        setUser(res.user);
        setCookie('sessionCookie', res.sessionCookie, { days: 10 });
        setCookie('accessToken', res.accessToken, { days: 10 });
        console.debug('Autenticación exitosa', res.user);
      })
      .catch(err => {
        deleteCookie('sessionCookie');
        deleteCookie('accessToken');
        history.replace('/login');
        console.error('Error de autenticación', err);
      })
      .finally(() => {
        setLoading(false);
      });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (history.action === 'PUSH' && mainPanel.current) {
      mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }, [history]);

  return (
    loading
      ? <Loader />
      : <div className="wrapper">
          <Sidebar {...props} routes={routes} bgColor="black" activeColor="info" />
          <div className="main-panel" ref={ mainPanel }>
            <Navbar {...props} />
            <Switch>
              {
                routes.map((prop, key) => (
                  <Route
                    path={prop.layout + prop.path}
                    render={props => <prop.component { ...props }/>}
                    key={key}
                  />
                ))
              }
            </Switch>
            <Footer fluid />
          </div>
        </div>
  );
}

export default Dashboard;
