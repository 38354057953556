const status = {
  1: 'Borrador',
  2: 'Solicitada',
  3: 'Auditada',
  4: 'Observada',
  5: 'Procesada',
  6: 'PedidoFactura',
  7: 'PendienteRecibo',
  8: 'Presentada',
  9: 'Anulada',
  10: 'Fuera de Término',
}

export const getStatusByName = (name) => {
  return Object.keys(status).find(key => status[key] === name)
}

export const getStatusNameById = (id) => {
  return status[id] || null
}

export default status