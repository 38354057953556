import React from 'react';
import { createBrowserHistory } from 'history';
import { Redirect, Router, Route, Switch } from 'react-router-dom';
import UserContext from './context/UserContext';
import StoreProvider from './context/StoreContext';
import ConfigContext from './context/ConfigContext';
import AdminLayout from './layouts/Admin';
import Login from 'views/Login';

const browserHistory = createBrowserHistory();

function App() {
  return (
    <Router history={ browserHistory }>
      <StoreProvider>
        <UserContext>
          <ConfigContext>
            <Switch>
              <Route path="/solicitudes" render={ props => <AdminLayout {...props} /> } />
              <Route path="/osacobrar" render={ props => <AdminLayout {...props} /> } />
              <Route path="/facturacion" render={ props => <AdminLayout {...props} /> } />
              <Route path="/report" render={ props => <AdminLayout {...props} /> } />
              <Route path="/help" render={ props => <AdminLayout {...props} /> } />
              <Route path="/login" render={ () => <Login /> } />
              <Route render={ () => <Redirect to="/login" /> } />
            </Switch>
          </ConfigContext>
        </UserContext>
      </StoreProvider>
    </Router>
  );
}

export default App;
