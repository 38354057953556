import { APIResponse } from 'types/Request';
import { getCookie } from 'helpers';
import config from 'config';

export interface Setting<T> {
  key: string;
  type: string;
  title: string;
  description: string;
  value: T;
}

export type Config = Setting<any>[];

export async function getConfig(): Promise<Config | undefined> {
  try {
    const url = new URL(`${ config.baseUrl }/system/config`);

    type Response = APIResponse<Config>

    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCookie('accessToken'),
      },
    });

    if (response.status !== 200) {
      return;
    }

    const content = await response.json() as Response;
    const { payload } = content;

    return payload;
  } catch (err) {
    throw new Error('Ocurrió un problema inesperado al momento de intentar recuperar la configuración.');
  }
}
