import React, { createContext, ReactNode, useContext, useState, useEffect } from 'react';
import { Config, getConfig } from 'features/config/configAPI';
import { useUser } from './UserContext';


interface ContextData {
  getSetting: <T>(key: string) => T | undefined;
}

export const ConfigContext = createContext<ContextData | undefined>(undefined);

interface ProviderProps {
  children: ReactNode;
}

export default function Provider({ children }: ProviderProps) {
  const user = useUser();
  const [config, setConfig] = useState<Config>([]);

  // https://stackoverflow.com/a/41112882/4467281
  const getSetting = <T extends unknown>(key: string) => {
    const setting = config.find(s => s.key === key);

    if (!setting) {
      return;
    }

    return setting.value as T;
  };

  const value = {
    getSetting,
  };

  useEffect(() => {
    if (!user.data) {
      return;
    }

    getConfig()
      .then(data => {
        if (data && Array.isArray(data)) {
          setConfig(data);
          return;
        }

        setConfig([]);
      });
  }, [user.data]);

  return (
    <ConfigContext.Provider value={ value }>
      { children }
    </ConfigContext.Provider>
  );
};

export const useConfig = () => {
  const context = useContext(ConfigContext);

  if (!context) {
    throw new Error('useConfig must be used within the Config Provider');
  }

  return context;
};
