import React, { ChangeEvent, useState } from 'react';
import { Col, Label, InputGroup, Input, InputGroupText } from 'reactstrap';

interface Afiliado2Props {
  disabled: boolean;

  onChange: (recipient: string[]) => void;
}

export default function Afiliado2(props: Afiliado2Props) {
  const { disabled = false, onChange } = props;

  const [recipient, setRecipient] = useState({
    id: '',
    subid: '',
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newRecipient = {
      ...recipient,
      [event.target.name]: event.target.value,
    };

    setRecipient(newRecipient);

    onChange([newRecipient.id, newRecipient.subid]);
  };

  return (
    <Col sm={ 12 } md={ 4 }>
      <Label for="id">N° de Afiliado</Label>
      <InputGroup>
        <Input
          id="id"
          name="id"
          type="number"
          step={ 1 }
          min={ 1 }
          max={ 9999999999 }
          pattern="\d+"
          style={{ width: '35%', textAlign: 'right' }}
          value={ recipient.id }
          onChange={ handleInputChange }
          disabled={ disabled }
        />
        <InputGroupText style={{
          backgroundColor: 'transparent',
          borderRadius: 0,
          borderLeft: 'none',
          borderRight: 'none',
        }}>
          /
        </InputGroupText>
        <Input
          id="subid"
          name="subid"
          type="number"
          step={ 1 }
          min={ 1 }
          max={ 999 }
          pattern="\d+"
          value={ recipient.subid }
          onChange={ handleInputChange }
          disabled={ disabled }
        />
      </InputGroup>
    </Col>
  );
}
