import config from 'config';
import { getCookie } from 'helpers';
import { InsurerConsultorios, APIResponse } from 'types/Request';

interface GetConsultoriosOptions {
  insurerId: number;
  matriculateId: number;
  date?: string; // 2022-07-18
}

export async function getConsultorios(options: GetConsultoriosOptions): Promise<InsurerConsultorios | undefined> {
  try {
    const { insurerId, matriculateId, date } = options;

    const url = new URL(`${ config.baseUrl }/insurers/${ insurerId }/consultorios`);

    const params = new URLSearchParams({
      matriculate_id: matriculateId.toString(),
    });

    if (date) {
      params.append('date', date);
    }

    url.search = params.toString();

    type Response = APIResponse<InsurerConsultorios>

    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getCookie('accessToken'),
      },
    });

    if (response.status !== 200) {
      return;
    }

    const content = await response.json() as Response;

    if (content.status !== 'ok') {
      if (content.code === 'CONNECTION_ERROR') {
        return;
      }

      throw new Error('Ocurrió un problema inesperado al momento de intentar recuperar las prestaciones. ' +
        'Temporalmente no se podrá enviar la solicitud pero si guardar como Borrador. (1)');
    }

    return content.payload;
  } catch (err) {
    throw new Error('Ocurrió un problema inesperado al momento de intentar recuperar las prestaciones. ' +
      'Temporalmente no se podrá enviar la solicitud pero si guardar como Borrador. (2)');
  }
}
