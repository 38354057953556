import React from 'react';
import { Alert, Row, Col } from 'reactstrap';
import { Observation } from 'types/Request';

interface ObservationAlertProps {
  observations: Observation[];
}

export default function ObservationAlert(props: ObservationAlertProps) {
  const { observations } = props;

  // Si no hay observaciones no renderizamos nada
  if (!Array.isArray(observations) || !observations.length) {
    return null;
  }

  // Si la última observación no es del auditor, tampoco
  if (observations[observations.length - 1].source !== 'auditor') {
    return null;
  }

  const obs = observations[observations.length - 1];

  return (
    <Alert color="warning">
      <Row className="mb-2">
        <Col sm={12}>
          <span className='group-name observations'>
            Observaciones
            <p className="mt-2" key={ obs.id }>
              <strong>{ new Date(obs.createdAt).toLocaleString('es') }</strong>
              &nbsp;-&nbsp;
              { obs.text }
            </p>
          </span>
        </Col>
      </Row>
    </Alert>
  );
}
