import React, { useEffect, useState } from 'react'

// reactstrap components
import {
  Pagination as ReactPagination,
  PaginationItem,
  PaginationLink
} from "reactstrap"

function Pagination (props) {

  const DEFAULT_LIMIT = 10
  const DEFAULT_DISPLAY_MAX_PAGES = 5

  const { page, count, onChangePage } = props
  const [pagesList, setPages] = useState([page])
  const [pagesToDisplay, setPagesToDisplay] = useState([page])

  useEffect(() => {
    if (count) {
      const arr = []
      const displayArr = []
      if (count > 0) {
        const total = Math.ceil(count / DEFAULT_LIMIT)
        for (let i = 1; i <= total; i++) {
          arr.push(i)
        }
        for (let i = 1; i <= DEFAULT_DISPLAY_MAX_PAGES; i++) {
          if (i <= total) {
            displayArr.push(i)
          }
        }
        setPages(arr)
        setPagesToDisplay(displayArr)
      }
    }
  }, [count])

  useEffect(() => {
    if (page) {
      const copy = pagesToDisplay.slice()
      const display = []
      // on next page
      if (page > pagesToDisplay[pagesToDisplay.length - 1] && pagesList.indexOf(page) !== -1 && pagesToDisplay.indexOf(page) === -1) {
        for (let i = 0; i < DEFAULT_DISPLAY_MAX_PAGES; i++ ) {
          if (pagesList.indexOf(page + i) !== -1) {
            display.push(page + i)
          } else {
            // comment this if you don't want to have always DEFAULT_DISPLAY_MAX_PAGES elements
            const [first] = display
            display.unshift(first - 1)
          }
        }
        return setPagesToDisplay(display)
      }
      // on previous page
      else if ( (page + 1) === copy[0] && page < copy[0] && pagesToDisplay.indexOf(page) === -1 ) {
        for (let i = 0; i < DEFAULT_DISPLAY_MAX_PAGES; i++ ) {
          if (page - i > 0) {
            display.unshift(page - i)
          } else {
            // comment this if you don't want to have always DEFAULT_DISPLAY_MAX_PAGES elements
            const last = display[display.length - 1]
            display.push(last + 1)
          }
        }
        return setPagesToDisplay(display)
      }
      return setPagesToDisplay(copy)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const changePage = (newPage) => {
    if (newPage === page) return
    if (newPage > pagesList.length || newPage < 1) {
      return
    }
    return (
      // setActivePage(page),
      onChangePage(newPage)
    )
  }

  return (
    <>
      <nav aria-label="...">
        <ReactPagination
          className="pagination justify-content-end mb-0"
          listClassName="justify-content-end mb-0"
        >
          <PaginationItem className="">
            <PaginationLink
              // disabled={true}
              className='pagination--link'
              onClick={() => changePage(page - 1)}
              tabIndex="-1"
            >
              <i className="fas fa-angle-left" />
              <span className="sr-only">Previous</span>
            </PaginationLink>
          </PaginationItem>
          {
            pagesToDisplay.map(p => (
              <PaginationItem key={p} className={'pagination--item ' + (page === p ? "active" : null)}>
                <PaginationLink
                  className='pagination--link'
                  onClick={() => changePage(p)}
                >
                  { p }
                </PaginationLink>
              </PaginationItem>
            ))
          }
          <PaginationItem className='pagination--item'>
            <PaginationLink
              className='pagination--link'
              // disabled={true}
              onClick={() => changePage(page + 1) }
            >
              <i className="fas fa-angle-right" />
              <span className="sr-only">Next</span>
            </PaginationLink>
          </PaginationItem>
        </ReactPagination>
      </nav>
    </>
  )
}

export default Pagination