import React, { createContext, useState, useContext, useEffect } from 'react'

const StoreContext = createContext()

let initialState = {
  current_submission: {
    insurer_id: null,
    insurer_name: null,
    previously_authorized: null
  }
}

if (sessionStorage.getItem('cokiba')) {
  try {
    initialState = JSON.parse(sessionStorage.getItem('cokiba'))
  } catch (err) {
    sessionStorage.setItem('cokiba', JSON.stringify(initialState))
  }
}

export default function StoreProvider (props) {
  const { children } = props

  const [ state, setState ] = useState(initialState);

  // Actualizamos la información en el storage
  useEffect(() => {
    sessionStorage.setItem('cokiba', JSON.stringify(state))
  }, [state])

  const actions = {
    updateSubmission: (value) => {
      setState({
        ...state,
        current_submission: value
      })
    },
    resetSubmission: () => {
      setState({
        ...state,
        current_submission: initialState.current_submission
      })
    }
  }

  return (
    <StoreContext.Provider value={{ store: state,  ...actions }}>
      { children }
    </StoreContext.Provider>
  )
}

export const useStore = () => useContext(StoreContext)
