import React, { useEffect, useState, useContext } from "react"

import { Card, CardBody, CardFooter, Row, Col } from "reactstrap"

import Filters from '../../components/Bills/Filters'
import ContentTable from 'components/Bills/ContentTable'
import Pagination from 'components/Pagination'

import { getAll } from 'services/ApiService'
import { useHistory, useLocation } from "react-router"
import { UserContext } from "context"
import { scrollToTop } from "helpers"

import { useAlertPlus } from '../../hooks/useAlertPlus'
import AlertModal from '../../components/AlertModal'

function List () {

  const user = useContext(UserContext)
  const location = useLocation()
  const history = useHistory()
  const [loading, isLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [items, setItems] = useState([])
  const [currentOptions, setCurrentOptions] = useState({
    estado_id: 'pending',
    sort: {
      field: 'createdAt',
      dir: -1
    },
    page: 1,
    search: '',
    user: user.getId()
  })

  const [ showAlert, message, alertCallback, alert ] = useAlertPlus('')

  // Recuperamos los datos iniciales
  useEffect(() => {
    fetchData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (location.state && location.state.success !== undefined) {
      scrollToTop()
      history.replace({
        pathname: location.pathname,
        state: {}
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchData = async (newOptions) => {
    try {
      // Merge de las nuevas opciones con las viejas
      const options = Object.assign({}, currentOptions, newOptions)

      isLoading(true)

      // Hacemos la petición
      const res = await getAll('submission-orders', options)

      // Recuperamos algunos datos de los resultados
      const { rows, count } = res
      setItems(rows)
      setTotal(count)

      // Guardamos las opciones
      setCurrentOptions(options)
    } catch (err) {
      setItems([])
      setTotal(0)

      if (err.name === 'TypeError') {
        alert('Ha ocurrido un ERROR DE CONEXIÓN. Por favor intente nuevamente más tarde')
      } else {
        alert('Ha ocurrido un imprevisto al momento de obtener las facturas. Por favor intente nuevamente')
      }
    } finally {
      isLoading(false)
    }
  }

  const changePage = async (page) => {
    await fetchData({ page })
    setCurrentPage(page)
  }

  const changeFilter = (key, value) => {
    fetchData({
      [key]: value
    })
  }

  const changeOrder = (field, dir) => {
    fetchData({
      sort: {
        field,
        dir
      }
    })
  }

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Filters changeFilter={ changeFilter } />
          <Card>
            <CardBody>
              {/* Content Table */}
              <ContentTable
                isLoading={loading}
                items={items}
                changeOrder={ changeOrder }
                currentOrder={ currentOptions.sort }
              />
            </CardBody>
            <CardFooter className="py-4">
              <Pagination
                page={currentPage}
                count={total}
                onChangePage={ changePage }
              />
            </CardFooter>
          </Card>
        </Col>
        <AlertModal isOpen={ showAlert } message={ message } onClose={ alertCallback } />
      </Row>
    </div>
  )
}

export default List
