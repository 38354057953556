import React, { Fragment } from 'react'

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap"

function AlertModal (props) {
  const { isOpen, message, onClose } = props

  const close = () => {
    return onClose()
  }

  return (
    <>
      <Modal isOpen={isOpen} className='modal-dialog-centered'>
        <ModalHeader>
          Información
        </ModalHeader>
        <ModalBody>
          { message.split(/\r?\n/).map((text, idx) => <Fragment key={ idx }>{ text }<br /></Fragment>) }
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => close()}>Cerrar</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default AlertModal