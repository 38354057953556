import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardBody, Row, Col, Button } from 'reactstrap';
import { useStore } from '../../context/StoreContext';

export default function StepOnlineAuthorization() {
  const { store, resetSubmission } = useStore();

  const { id } = useParams<{ id?: string }>();

  const history = useHistory();

  let insurerComponent = null;

  // TODO: Cargar esta información en la BBDD
  const insurersWithOnlineAuthorization = [
    36, // Avalian (ex ACA Salud)
  ];

  const insurerId = store.current_submission.insurer_id;

  // Verificamos que se haya seleccionado una obra social que pueda autorizar online
  if (insurersWithOnlineAuthorization.findIndex(id => id === insurerId) === -1) {
    console.error('StepOnlineAuthorization', 'Se intentó acceder a la autorización online de una OOSS no implementada');
    resetSubmission();
    history.replace('/solicitudes/step-insurer-selection');
    return;
  }

  // Verificamos que no tenga autorización previa (segun el usuario)
  if (store.current_submission.previously_authorized) {
    console.error('StepOnlineAuthorization', 'Se intentó acceder a la autorización online de ' +
      'una solicitud con autorización previa');
    history.replace(`/solicitudes/${ id }/edit`);
    return;
  }

  // Seleccionamos el componente según el id de OOSS
  switch (insurerId) {
    case 36: insurerComponent = require('../Authorizations/Avalian').default(); break;
    default:
      console.error('StepOnlineAuthorization', 'Se intentó cargar el formulario de una OOSS no implementada');
      insurerComponent = null;
  }

  return (
    <div className="content">
      <Row>
        <Col sm={12}>
          <Card>
            <CardHeader>
              <Row style={{ alignItems: 'center' }}>
                <Col sm={8}>
                  <CardTitle tag='h5'>
                    Autorización Online{
                      store.current_submission.insurer_name ? ' - ' + store.current_submission.insurer_name : ''
                    }
                  </CardTitle>
                </Col>
                <Col sm={4} className="text-right">
                  <Button className='btn-round' color='primary' onClick={() => history.goBack()}>
                    Volver
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              { insurerComponent }
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
