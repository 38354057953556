import React, { ChangeEvent, useState } from 'react';
import { Col, Label, Input } from 'reactstrap';

interface Afiliado1Props {
  disabled: boolean;

  onChange: (recipient: string[]) => void;
}

export default function Afiliado1(props: Afiliado1Props) {
  const { disabled = false, onChange } = props;

  const [recipient, setRecipient] = useState('');

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRecipient(event.target.value);

    onChange([event.target.value]);
  };

  return (
    <Col sm={ 12 } md={ 4 }>
      <Label for="recipient_id">N° de Afiliado</Label>
      <Input
        id="recipient_id"
        name="recipient_id"
        type="number"
        step={ 1 }
        min={ 1 }
        max={ 9999999999 }
        pattern="\d+"
        style={{ textAlign: 'right' }}
        value={ recipient }
        onChange={ handleInputChange }
        disabled={ disabled }
      />
    </Col>
  );
}
