import React, { ChangeEvent, useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { post } from 'services/ApiService';
import { useAlertPlus } from 'hooks';
import { useStore } from '../../context/StoreContext';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row } from 'reactstrap';

import AlertModal from '../../components/AlertModal';
import DNI from 'components/Validation/DNI';
import Afiliado2 from 'components/Validation/Afiliado2';
import Afiliado3 from 'components/Validation/Afiliado3';
import formatAffiliate from 'helpers/formatAffiliate';
import Afiliado1 from 'components/Validation/Afiliado1';

type ValidatorTypes = 'dni' | 'affiliate1' | 'affiliate2' | 'affiliate3';

interface FormData {
  recipient_id: string[];
  recipient_type: ValidatorTypes;
}

interface RecipientData {
  id: string[];
  name: string;
  plan: string;
  tax: string;
}

export default function StepAffiliateValidation() {
  const history = useHistory();
  const { store, resetSubmission, updateSubmission } = useStore();

  const [alertShow, alertMessage, alertCallback, alert] = useAlertPlus('');

  const insurerId = store.current_submission.insurer_id;

  const [formData, setFormData] = useState<FormData>({
    recipient_id: [],
    recipient_type: 'affiliate2',
  });

  const [recipient, setRecipient] = useState<RecipientData>({
    id: [],
    name: '',
    plan: '',
    tax: '',
  });

  const [isLoading, setLoading] = useState(false);

  const handleRecipientChange = (recipient: string[]) => {
    setFormData({
      ...formData,
      recipient_id: recipient,
    });
  };

  const handleVerification = async () => {
    try {
      setLoading(true);

      const response = await post('insurers/validate-affiliate', {
        ...formData,
        insurer_id: insurerId,
      });

      // Errores
      if (response.status !== 'ok') {
        if (response.message) {
          alert(response.message);
        } else {
          alert('Hubo un problema al intentar validar el afiliado. Por favor, intente nuevamente más tarde');
        }

        setLoading(false);
        return;
      }

      let parsedId;
      switch (formData.recipient_type) {
        case 'affiliate2':
          parsedId = response.payload.recipient_id.split('/');
          if (parsedId.length === 1) {
            parsedId[1] = parsedId[0].substring(parsedId[0].length - 2);
            parsedId[0] = parsedId[0].substring(0, parsedId[0].length - 2);
          }
          break;
        case 'affiliate3':
          parsedId = response.payload.recipient_id.split('-');
          if (parsedId.length === 1) {
            parsedId[2] = parsedId[0].substring(parsedId[0].length - 1);
            parsedId[1] = parsedId[0].substring(parsedId[0].length - 3, parsedId[0].length - 1);
            parsedId[0] = parsedId[0].substring(0, parsedId[0].length - 3);
          }
          break;
        default:
          parsedId = Array.isArray(response.payload.recipient_id)
            ? response.payload.recipient_id
            : [response.payload.recipient_id];
      }

      setRecipient({
        id: parsedId,
        name: response.payload.recipient_name,
        plan: response.payload.plan_text,
        tax: response.payload.tax,
      });
    } catch (err) {
      console.error(err);
      alert('Hubo un problema al intentar validar el beneficiario. Por favor, intente nuevamente más tarde');
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmation = async () => {
    try {
      setLoading(true);

      const response = await post('insurers/validate/confirm', {
        insurer_id: insurerId,
        previously_authorized: store.current_submission.previously_authorized,
        impairment: store.current_submission.previously_authorized,
        extended_quota: store.current_submission.extended_quota,
        ...formData,
        recipient_type: formData.recipient_type == 'dni' ? 'affiliate1' : formData.recipient_type,
        recipient_id: recipient.id,
      });

      // Errores
      if (response.status !== 'ok') {
        if (response.message) {
          alert(response.message);
        } else {
          alert('Hubo un problema al intentar autorizar la práctica. Por favor, intente nuevamente más tarde');
        }
        setLoading(false);
        return;
      }

      const id = response.payload?.billing_request?.id;
      const recipientId = response.payload?.recipient?.id;

      if (!id || !recipientId) {
        alert(`Se produjo un error al intentar crear la solicitud. Por favor, intente nuevamente`, () => {
          history.replace(`/solicitudes`);
        });
      }

      updateSubmission({
        ...store.current_submission,
        request_id: id,
        recipient_id: recipientId,
      });

      switch (insurerId) {
        case 22: // COMEI
          history.replace(`/solicitudes/${ id }/edit-comei`);
          break;
        case 26: // AMFFA
          history.replace(`/solicitudes/${ id }/edit-amffa`);
          break;
        case 36: // Avalian
          history.replace(`/solicitudes/${ id }/edit-avalian`);
          break;
        case 39: // COESBA
          history.replace(`/solicitudes/${ id }/edit-coesba`);
          break;
        case 44: // OSAM
          history.replace(`/solicitudes/${ id }/edit-osam`);
          break;
        case 46: // UP
          history.replace(`/solicitudes/${ id }/edit-up`);
          break;
        case 55: // Medife
          history.replace(`/solicitudes/${ id }/edit-medife`);
          break;
        case 57: // Swiss
          if (store.current_submission.impairment) {
            history.replace(`/solicitudes/${ id }/edit-swiss-d`);
          } else {
            history.replace(`/solicitudes/${ id }/edit-swiss`);
          }
          break;
        case 66:
          if (store.current_submission.previously_authorized) {
            history.replace(`/solicitudes/step-authorization-validation`);
          } else {
            history.replace(`/solicitudes/${ id }/edit-sancor`);
          }
          break;
        case 74:
          if (store.current_submission.previously_authorized) {
            history.replace(`/solicitudes/step-authorization-validation`);
          } else {
            history.replace(`/solicitudes/${ id }/edit-federada`);
          }
          break;
        default:
          history.replace(`/solicitudes/${ id }/edit-sessions`);
      }
    } catch (err) {
      console.error(err);
      alert('Hubo un problema al intentar validar el número de beneficiario. Por favor, intente nuevamente más tarde');
      setLoading(false);
    }
  };

  const handleTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRecipient({
      id: [],
      name: '',
      plan: '',
      tax: '',
    });

    setFormData({
      recipient_type: event.target.value as ValidatorTypes,
      recipient_id: [],
    });
  };

  const handleCancelation = () => {
    resetSubmission();
    history.replace('/solicitudes/step-insurer-selection');
    return;
  };

  const validators = useMemo(() => {
    if (!insurerId) {
      return [];
    }

    switch (insurerId) {
      case 22: return ['affiliate3']; // COMEI
      case 26: return ['affiliate1']; // AMFFA
      case 36: return ['affiliate2']; // Avalian
      case 39: return ['affiliate1']; // COESBA
      case 44: return ['affiliate2', 'dni']; // OSAM
      case 46: return ['affiliate2']; // UP
      case 55: return ['affiliate1']; // Medife
      case 57: return ['affiliate1']; // Swiss
      default: return ['dni', 'affiliate2'];
    }
  }, [insurerId]);

  useEffect(() => {
    setFormData({
      recipient_id: [],
      recipient_type: validators[0] as ValidatorTypes,
    });
  }, [validators]);

  return (
    <>
      <Container className="content">
        <Card>
          <CardHeader>
            <CardTitle tag='h5'>Validar Afiliado</CardTitle>
            {
              validators.length > 0
                ? <FormGroup style={{ marginLeft: '20px' }}>
                    {
                      validators.map(validator => (
                        <FormGroup check key={ validator }>
                          <Input
                            type="radio"
                            name="recipient_type"
                            onChange={ handleTypeChange }
                            id={ validator }
                            value={ validator }
                            checked={ formData.recipient_type === validator }
                            disabled={ recipient.id.length > 0 }
                          />
                          <Label
                            for={ validator }
                            style={{ paddingLeft: '15px' }}
                            check
                          >
                            {
                              validator == 'dni'
                                ? 'Por número de documento'
                                : 'Por número de afiliado'
                            }
                          </Label>
                        </FormGroup>
                      ))
                    }
                  </FormGroup>
                : null
            }
          </CardHeader>
          <CardBody>
            <FormGroup row>
              {
                formData.recipient_type === 'affiliate1'
                  ? <Afiliado1 disabled={ isLoading || recipient.id.length > 0 } onChange={ handleRecipientChange } />
                  : null
              }
              {
                formData.recipient_type === 'affiliate2'
                  ? <Afiliado2 disabled={ isLoading || recipient.id.length > 0 } onChange={ handleRecipientChange } />
                  : null
              }
              {
                formData.recipient_type === 'affiliate3'
                  ? <Afiliado3 disabled={ isLoading || recipient.id.length > 0 } onChange={ handleRecipientChange } />
                  : null
              }
              {
                formData.recipient_type === 'dni'
                  ? <DNI disabled={ isLoading || recipient.id.length > 0 } onChange={ handleRecipientChange } />
                  : null
              }
              <Col sm={ 12 } md={ 2 }>
                <Label>&nbsp;</Label><br />
                <Button
                  color="primary"
                  style={{ margin: 0 }}
                  onClick={ handleVerification }
                  disabled={ isLoading || recipient.id.length > 0 }
                >
                  Validar
                </Button>
              </Col>
            </FormGroup>
            <small style={{ color: '#e67f83' }} className={`mt-2 ${ insurerId == 46 ? 'd-block' : 'd-none' }`}>
              Ingresar número de credencial excluyendo el último dígito
            </small>
            <Row className={ recipient.id.length > 0 ? 'd-block' : 'd-none' }>
              <Col sm={ 12 } md={ 6 }>
                <strong>Número de Beneficiario</strong>: { formatAffiliate(insurerId, recipient.id) }<br />
                <strong>Nombre y Apellido</strong>: { recipient.name }<br />
                <strong>Plan</strong>: { recipient.plan }<br />
                { recipient.tax && <><strong>Condición Impositiva</strong>: { recipient.tax }<br /></> }
              </Col>
              <Col sm={ 6 } style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Button
                  color="danger"
                  disabled={ isLoading }
                  onClick={ handleCancelation }
                >
                  Cancelar
                </Button>
                <Button
                  color="success"
                  onClick={ handleConfirmation }
                  disabled={ isLoading }
                >
                  Confirmar
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
      <AlertModal isOpen={ alertShow } message={ alertMessage } onClose={ alertCallback } />
    </>
  );
}
