import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import { getExpirations } from 'features/expirations/expirationsAPI';
import { getCookie } from 'helpers';
import { getQueryString } from 'services/ApiService';
import config from 'config';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

interface GraphBar {
  key: number;
  label: string;
  value: number;
}

type GraphData = GraphBar[];

// Initial data para crear las columnas del gráfico con solicitudes en 0.
const defaultData: GraphData = [
  {
    key: 30,
    label: 'Menores a 30 días',
    value: 0,
  },
  {
    key: 60,
    label: 'Entre 30 y 60 días',
    value: 0,
  },
];

function Report() {
  const [expirationsParsed, setExpirationsParsed] = useState<GraphData>(defaultData);
  const [expirationsEmpty, setExpirationsEmpty] = useState(0);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);

  useEffect(()=>{
    getExpirations()
      .then(expirations => {
        if (!expirations) {
          return null;
        }

        // Solicitudes sin sesiones
        const idx = expirations.findIndex(d => d.vencimiento == 0);
        if (idx > -1) {
          setExpirationsEmpty(expirations[idx].solicitudes);
        }

        // Solicitudes con sesions
        const parsedData = defaultData.map(currrent => {
          const idx = expirations.findIndex(d => d.vencimiento == currrent.key);
          if (idx == -1) {
            return currrent;
          }

          return {
            key: currrent.key,
            label: currrent.label,
            value: expirations[idx].solicitudes,
          };
        });

        setExpirationsParsed(parsedData);
      })
      .catch((err: any) => {
        alert('Se produjo un error al intentar recuperar la información de la gráfica. ' +
            'Por favor, intente nuevamente más tarde',
        );
        console.error('Reports', 'Error inesperador al intentar recuperar la gráfica');
        console.info(err);
      });
  }, []);

  const downloadExcel = async () => {
    try {
      setIsLoadingExcel(true);

      const queryString = getQueryString({ estado_id: [1, 4],
        sort: {
          field: 'createdAt',
          dir: -1,
        } });

      const res = await fetch(
        `${config.baseUrl}/requests/excel${queryString}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: getCookie('accessToken'),
          },
        },
      );

      const blob = await res.blob();

      const file = window.URL.createObjectURL(blob);

      window.location.assign(file);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingExcel(false);
    }
  };

  return (
    <div className='content'>
      <Row>
        <Col>
          <Card className='card-user'>

            <CardBody>
              <Row className="pt-4 pb-4">
                <Col sm={6}>
                  <h5>Gráfica según condición</h5>
                  <small>
                    Se muestra la cantidad de días transcurridos desde la fecha de la primera sesión de la solicitud.
                    Las solicitudes que hayan superados los 60 días automáticamente se marcarán como{' '}
                    <strong>Fuera de termino</strong> a menos que hayan sido enviadas para auditoría.
                  </small>
                  <br /><br />
                  <ResponsiveContainer height={300} width="100%">
                    <BarChart data={expirationsParsed}>
                      <Bar dataKey="value" fill="#8884d8" />
                      <YAxis />
                      <XAxis dataKey="label" />
                      <Tooltip />
                    </BarChart>
                  </ResponsiveContainer>
                  {
                    expirationsEmpty > 0
                      ? <div style={{ width: '100%', textAlign: 'center', fontSize: '.75rem' }}>
                            No se incluyeron { expirationsEmpty } solicitudes porque no tienen sesiones cagadas o ya
                            fueron auditadas y/o facturadas
                        </div>
                      : null
                  }
                </Col>
                <Col sm={6}>
                  <h5>Descargar Excel con detalle</h5>
                  <small>
                    Excel con todas las solicitudes sin auditar u observadas con información sobre la fecha de
                    vencimiento
                  </small>
                  <br /><br />
                  <Button color="light" onClick={ downloadExcel } disabled={ isLoadingExcel }>
                    {
                      isLoadingExcel
                        ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                        : <>Descargar Excel</>
                    }
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Report;
