export default function formatAffiliate(insurer: number, recipient: string | string[]): string {
  if (!recipient) {
    return '';
  }

  if (Array.isArray(recipient)) {
    return formatArrayAffiliate(insurer, recipient);
  }

  return formatStringAffiliate(insurer, recipient);
}

function formatArrayAffiliate(insurer: number, recipient: string[]): string {
  switch (insurer) {
    case 22: // COMEI
      return recipient.join('-');
    case 36: // Avalian
      return recipient.join('/');
    case 57: // Swiss
      return recipient.join();
    case 66: // Sancor
      return recipient.join('/');
    case 74: // Federada
      return recipient.join('-');
    case 77: // OSPe
      const integrante = recipient.pop();
      return recipient.join('-') + '/' + integrante;
    default:
      return recipient.join();
  }
}

function formatStringAffiliate(insurer: number, recipient: string): string {
  let parsedRecipient;

  switch (insurer) {
    case 22: // COMEI
      parsedRecipient = recipient.split('-');
      if (parsedRecipient.length === 1) {
        parsedRecipient[2] = parsedRecipient[0].substring(parsedRecipient[0].length - 1);
        parsedRecipient[1] = parsedRecipient[0].substring(parsedRecipient[0].length - 3, parsedRecipient[0].length - 1);
        parsedRecipient[0] = parsedRecipient[0].substring(0, parsedRecipient[0].length - 3);
      }
      break;
    case 36: // Avalian
    case 66: // Sancor
      parsedRecipient = recipient.split('/');
      if (parsedRecipient.length === 1) {
        parsedRecipient[1] = parsedRecipient[0].substring(parsedRecipient[0].length - 2);
        parsedRecipient[0] = parsedRecipient[0].substring(0, parsedRecipient[0].length - 2);
      }
      break;
    case 74: // Federada
      parsedRecipient = recipient.split('-');
      if (parsedRecipient.length === 1) {
        parsedRecipient[1] = parsedRecipient[0].substring(parsedRecipient[0].length - 2);
        parsedRecipient[0] = parsedRecipient[0].substring(0, parsedRecipient[0].length - 2);
      }
      break;
    default:
      return recipient;
  }

  return formatArrayAffiliate(insurer, parsedRecipient);
}
