import React, { createContext, ReactNode, useContext, useState } from 'react';

interface User {
  id: string;
  matricula: string;
  nombre: string;
  apellido: string;
};

interface ContextData {
  getUser: () => User | null;
  setUser: (user: User | null) => void;
  getId: () => string | null;
  getMatricula: () => string | null;
  getNombreApellido: () => string | null;
  data: User | null;
}

export const UserContext = createContext<ContextData | undefined>(undefined);

interface ProviderProps {
  children: ReactNode;
}

export default function Provider({ children }: ProviderProps) {
  const [userData, setUserData] = useState<User | null>(null);

  const getUser = () => {
    return userData;
  };

  const setUser = (user: User | null) => {
    setUserData(user);
  };

  const getId = () => {
    return userData ? userData.id : null;
  };

  const getMatricula = () => {
    return userData ? userData.matricula : null;
  };

  const getNombreApellido = () => {
    return userData ? `${userData.nombre} ${userData.apellido}` : null;
  };

  const value = {
    getUser,
    setUser,
    getId,
    getMatricula,
    getNombreApellido,
    data: userData,
  };

  return (
    <UserContext.Provider value={ value }>
      { children }
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUser must be used within the User Provider');
  }

  return context;
};
