import React from 'react'
import ReactDOM from 'react-dom'

import 'bootstrap/dist/css/bootstrap.css'
import 'assets/scss/paper-dashboard.scss?v=1.2.0'
import 'assets/sass/app.sass'

import App from './App'

ReactDOM.render(
  <App />,
  document.getElementById('root')
)
