import React, { useRef, useState } from 'react'
import { Input, Button } from "reactstrap"
import config from 'config';

const accept = ['application/pdf']

export default function (props) {
  const { required, name } = props

  const fileInput = useRef(null)

  const [file, setFile] = useState(null)
  const [url, setURL] = useState(null)
  const [sizeError, setSizeError] = useState(false)

  const select = () => {
    // Verificamos si hay archivos seleccionado
    if (!fileInput.current.files.length) {
      return
    }

    // Controlamos el tamaño del archivo
    if (fileInput.current.files[0].size > config.maxFileSize) {
        fileInput.current.value = ''
        setSizeError(true)
        setFile(null)
        setURL(null)
        return
    }

    setSizeError(false)

    // Si ya había una URL creada, la liberamos
    if (url) {
      URL.revokeObjectURL(url)
    }

    // Seleccionamos el primer archivo
    const newFile = fileInput.current.files[0]
    // Generamos una URL para la vista previa
    const newURL = URL.createObjectURL(newFile)

    setFile(newFile)
    setURL(newURL)

    if (!fileInput.current.classList.contains('hidden')) {
      fileInput.current.classList.add('hidden')
    }
  }

  const replace = () => {
    fileInput.current.click()
  }

  return (
    <>
      <Input
      innerRef={ fileInput }
      type="file"
      accept={ accept.join(', ') }
      onChange={ () => select() }
      required={ required }
      name={ name }
      className={ 'overflow-hidden' } />
      <label className={`required-text ${ !sizeError ? 'hidden' : ''}`}>El peso del archivo no debe superar los 3 MB</label>
      {
        file
        ? <>
            <Button color="primary" onClick={ () => window.open(url, "_blank") }>Ver adjunto</Button>
            <Button color="warning" onClick={ () => replace() }>Reemplazar</Button>
          </>
        : null
      }
    </>
  )
}